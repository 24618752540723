import React from "react";
import { ContentCopy, CopyAll } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import config from "../../Config";

export default function CopyText({
  invoiceID,
  data,
  open,
  handleClose,
  autoCopy = false,
}) {
  const handleTableCopy = () => {
    let copyText = document.getElementById("posCopyTable");
    if (document.createRange && window.getSelection) {
      let range = document.createRange();
      let sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(copyText);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(copyText);
        sel.addRange(range);
      }

      navigator.clipboard.writeText(sel.toString());

      sel.removeAllRanges();
      toast.info("Copied to clipboard", {
        toastId: "copyToClipboard",
        icon: <ContentCopy />,
      });
    }
  };

  const theme = useTheme();

  const tdStyle = {
    border: `1px solid ${theme.palette.divider}`,
    textWrap: "nowrap",
    padding: "3px",
  };

  // const orderIDs = data?.orderDetails.map((order) => order.id);
  // const confirmations = data?.orderDetails.map(
  //   (order) => order.confirmations.map((conf) => conf.type) || []
  // );

  const orderIDs = React.useMemo(() => {
    let ids = [];
    data?.orderDetails.forEach((order) => {
      ids.push(order.id);
    });
    return ids;
  }, [data?.orderDetails]);

  const confirmations = React.useMemo(() => {
    let confs = [];
    data?.orderDetails.forEach((order) => {
      confs.push(
        order.confirmations.map((conf) => `${conf.type} | ${conf.value}`) || ""
      );
    });
    return confs;
  }, [data?.orderDetails]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Paper variant="outlined" sx={{ p: 1, overflowX: "auto" }}>
          <table
            id="posCopyTable"
            cellSpacing="0"
            style={{ borderCollapse: "collapse" }}
          >
            <tbody>
              <tr>
                <td style={tdStyle}>{invoiceID}</td>
                <td style={tdStyle}>
                  {new Date(data?.date).toLocaleDateString("en-GB")}
                </td>
                <td style={tdStyle}>{data?.clientName}</td>
                <td style={tdStyle}>{data?.currency}</td>
                <td style={tdStyle}>{data?.total}</td>
                <td style={tdStyle}>{data?.total * config.VAT}</td>
                <td style={tdStyle}>{orderIDs.join(" / ")}</td>
                <td style={tdStyle}>
                  {confirmations.map((conf) => conf.join("; "))}
                </td>
                <td style={tdStyle}>
                  {data?.items
                    .map((item) => `${item.partNumber} (${item.quantity})`)
                    .join("; ")}
                </td>
                <td style={tdStyle}>
                  {`=HYPERLINK(CONCATENATE("https://portal.tec-today.com/invoice/summary?invoiceID=","${data?.id}"),"Invoice")`}
                </td>
                <td style={tdStyle}>
                  {`=HYPERLINK(CONCATENATE("https://portal.tec-today.com/adminOrderManager/overview?i=","${orderIDs.join(
                    "/"
                  )}"),"Order")`}
                </td>
              </tr>
            </tbody>
          </table>
        </Paper>
        <Stack alignItems={"flex-end"} sx={{ mt: 1 }}>
          <Button
            ref={(el) => {
              if (el && autoCopy) {
                el.click();
                handleClose();
              }
            }}
            startIcon={<CopyAll />}
            onClick={handleTableCopy}
            color="info"
            variant="contained"
          >
            Copy to clipboard
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
