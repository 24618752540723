import React from "react";

import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Avatar,
  SvgIcon,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Alert,
} from "@mui/material";

import {
  ArrowBack,
  ArrowForward,
  CheckCircleRounded,
  Delete,
  DoneAll,
  Handyman,
  NavigateNext,
  Timelapse,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import config from "../../Config";

import {
  getClientByID,
  searchClient,
  getQuoteByIDTable,
} from "../../API/Sales";
import { getRecentQuotes } from "../../API/Sales";

import NumberFormat from "../../../Components/common/NumberFormater";
import QuoteTable from "../tables/QuoteSelect";
import ItemsArea from "./ItemArea";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import { FileUploader } from "react-drag-drop-files";
import DateTimePicker from "./DateTimePicker";

export default function Order({ orderData, onSubmit }) {
  const [step, setStep] = React.useState(0);
  const maxStep = 5;

  const [quoteID, setQuoteID] = React.useState(orderData?.quoteID || []),
    [quoteSkipped, setQuoteSkipped] = React.useState(
      orderData?.quoteSkipped || false
    ),
    [currency, setCurrency] = React.useState(orderData?.currency || "KES"),
    [orderDetails, setOrderDetails] = React.useState(
      orderData?.orderDetails || ""
    ),
    [logisticDetails, setLogisticDetails] = React.useState(
      orderData?.logisticDetails || ""
    ),
    [clientID, setClientID] = React.useState(orderData?.clientID || ""),
    [vatExempt, setVatExempt] = React.useState(orderData?.vat || false),
    [items, setItems] = React.useState(orderData?.items || []),
    [file, setFile] = React.useState([]);

  const [orderType, setOrderType] = React.useState(null);
  const [orderExpected, setOrderExpected] = React.useState("");

  React.useEffect(() => {
    if (quoteID.length > 0) setQuoteSkipped(false);
  }, [quoteID]);

  React.useEffect(() => {
    if (quoteID.length > 0) {
      setCurrency(quoteID[0].currency);
      setVatExempt(quoteID[0].vatExempt);
    }
  }, [quoteID]);

  const clearAndSkip = () => {
    setQuoteID([]);
    setQuoteSkipped(true);
  };

  const submitOrder = () => {
    const data = {
      quoteRefrences: quoteID,
      currency: currency,
      vatExempt: vatExempt,
      orderDetails: orderDetails,
      logisticDetails: logisticDetails,
      clientID: clientID.id,
      items: items,
      orderType: orderType,
      orderExpected: orderExpected,
      confirmationType: "",
      confirmationDetails: "",
      attachments: file,
    };
    onSubmit(data);
  };

  const nextStep = () => {
    if (step < maxStep) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const MoveStep = ({ nextStepAllowed, onChange }) => {
    const handlePrev = () => {
      if (onChange) onChange();
      prevStep();
    };

    const handleNext = () => {
      if (onChange) onChange();
      if (maxStep > step) {
        nextStep();
        return;
      }
      submitOrder();
    };

    return (
      <Stack spacing={2} direction="row" justifyContent="center" sx={{ my: 1 }}>
        {step > 0 && (
          <Button
            color="primary"
            onClick={handlePrev}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
        )}

        <Button
          color="primary"
          variant={maxStep > step ? "text" : "contained"}
          onClick={handleNext}
          disabled={!nextStepAllowed}
          endIcon={maxStep > step ? <ArrowForward /> : <DoneAll />}
        >
          {maxStep > step ? "Next" : "Submit Order"}
        </Button>
      </Stack>
    );
  };

  const currentStep = () => {
    switch (step) {
      case 0:
        return (
          <QuoteManager
            selectedQuotes={quoteID}
            setSelectedQuotes={setQuoteID}
            hasSkipped={quoteSkipped}
            handleSkip={setQuoteSkipped}
            clearAndSkip={clearAndSkip}
            setClientID={setClientID}
            MoveStep={MoveStep}
          />
        );
      case 1:
        return (
          <DetailsArea
            clientID={clientID}
            setClientID={setClientID}
            quoteClient={
              quoteID[0]
                ? {
                    id: quoteID[0]?.clientID,
                    name: quoteID[0]?.label,
                  }
                : ""
            }
            currency={currency}
            setCurrency={setCurrency}
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
            logisticDetails={logisticDetails}
            setLogisticDetails={setLogisticDetails}
            vatExempt={vatExempt}
            setVatExempt={setVatExempt}
            MoveStep={MoveStep}
          />
        );

      case 2:
        return (
          <OrderItemArea
            itemList={items}
            setItemList={setItems}
            quotes={quoteID}
            hasVat={!vatExempt}
            currency={currency}
            MoveStep={MoveStep}
          />
        );
      case 3:
        return (
          <OrderTypeArea
            orderType={orderType}
            setOrderType={setOrderType}
            orderExpected={orderExpected}
            setOrderExpected={setOrderExpected}
            MoveStep={MoveStep}
          />
        );
      case 4:
        return (
          <UploadFileArea file={file} setFiles={setFile} MoveStep={MoveStep} />
        );
      case 5:
        return (
          <Finalisation
            data={{
              quoteRefrences: quoteID,
              currency: currency,
              vatExempt: vatExempt,
              orderDetails: orderDetails,
              clientID: clientID,
              items: items,
              confirmationType: "",
              confirmationDetails: "",
              orderType: orderType,
              orderExpected: orderExpected,
            }}
            MoveStep={MoveStep}
          />
        );
      default:
        return null;
    }
  };

  const StepName = ({ text, stepVal, completed }) => {
    const theme = useTheme();
    return (
      <Stack spacing={1} direction="row" alignItems="center">
        {completed ? (
          <SvgIcon
            component={CheckCircleRounded}
            color="primary"
            sx={{ fontSize: "2.8rem" }}
          />
        ) : (
          <Avatar sx={{ background: theme.palette.secondary.main }}>
            {stepVal + 1}
          </Avatar>
        )}

        <Typography color={step === stepVal ? "secondary" : "inherit"}>
          {text}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      alignContent="center"
      justifyContent="space-between"
      alignItems="center"
      sx={{ minHeight: "calc( 100vh - 203px )" }}
    >
      <Stack
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        divider={<NavigateNext />}
        sx={{ my: 2 }}
      >
        <StepName
          text="Refrence Quote"
          stepVal={0}
          completed={quoteID.length > 0}
        />
        <StepName
          text="Order Details"
          stepVal={1}
          completed={currency && orderDetails}
        />
        <StepName text="Add Items" stepVal={2} completed={items.length > 0} />
        <StepName text="Order Type" stepVal={3} />
        <StepName text="Upload File" stepVal={4} />
        <StepName text="Finalise" stepVal={5} />
      </Stack>
      {currentStep()}
    </Stack>
  );
}

function QuoteManager({
  selectedQuotes,
  setSelectedQuotes,
  hasSkipped,
  handleSkip,
  clearAndSkip,
  MoveStep,
}) {
  const [quoteList, setQuoteList] = React.useState(selectedQuotes || []),
    [quotes, setQuotes] = React.useState([]),
    [loading, setLoading] = React.useState(false),
    [showingRecents, setShowingRecents] = React.useState(false),
    [showTextField, setShowTextField] = React.useState(false),
    [searchText, setSearchText] = React.useState(""),
    [foundQuote, setFoundQuotes] = React.useState([]),
    [showSkipped, setShowSkipped] = React.useState(false);

  const skipped = hasSkipped;

  React.useEffect(() => {
    if (loading)
      getRecentQuotes().then((res) => {
        res.forEach((quote) => {
          quote.date_created = new Date(quote.date_created).toLocaleString();
          quote.date_quoted = new Date(quote.date_quoted).toLocaleString();
        });
        setQuotes(res);
        setLoading(false);
      });
  }, [loading]);

  const toggleShowRecents = () => {
    if (showingRecents) {
      setLoading(false);
      setShowingRecents(false);
      return;
    }
    setLoading(true);
    setShowingRecents(true);
  };

  const toggleShowTextField = () => {
    setShowTextField(!showTextField);
    setSearchText("");
    setFoundQuotes([]);
  };

  const manageSkip = () => {
    if (skipped) {
      handleSkip(false);
      return;
    }
    setShowSkipped(true);
  };

  React.useEffect(() => {
    setSelectedQuotes(quoteList);
  }, [quoteList, setSelectedQuotes]);

  const addQuote = (quote) => {
    if (quoteList.some((q) => q.quote_no === quote.quote_no)) {
      return;
    }
    setQuoteList([...quoteList, quote]);
  };

  const removeQuote = (quote) => {
    const current = quoteList;
    setQuoteList(current.filter((q) => q.quote_no !== quote.quote_no));
  };

  const searchQuote = () => {
    setFoundQuotes([]);
    getQuoteByIDTable(searchText).then((res) => {
      if (res.status === "success") {
        const quote = res.data;
        quote.date_created = new Date(quote.date_created).toLocaleString();
        quote.date_quoted = new Date(quote.date_quoted).toLocaleString();
        setFoundQuotes([quote]);
        return;
      }
      toast.error(res.message);
    });
  };

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1, maxWidth: "100%" }}>
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          direction={{ xs: "column", md: "row" }}
          sx={{ my: 1 }}
        >
          <Button
            color={showingRecents ? "secondary" : "primary"}
            variant="contained"
            onClick={toggleShowRecents}
          >
            {showingRecents ? "Hide Recent Quotes" : "Recent Quotes (500)"}
          </Button>
          <Button
            color={showTextField ? "secondary" : "primary"}
            variant="contained"
            onClick={toggleShowTextField}
          >
            {showTextField ? "Hide Quote Number" : "Enter Quote Number"}
          </Button>
          <Button
            color={skipped ? "secondary" : "primary"}
            variant="contained"
            onClick={manageSkip}
          >
            {skipped
              ? "Cancel adding details manually"
              : "Add details manually"}
          </Button>
        </Stack>
        <Dialog open={showSkipped} onClose={() => setShowSkipped(false)}>
          <DialogTitle>Add details manually</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? This means you will have to add items manually and
              will remove any quotes you have already added.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                clearAndSkip();
                setShowSkipped(false);
              }}
              color="success"
              variant="contained"
            >
              Confirm
            </Button>
            <Button
              onClick={() => setShowSkipped(false)}
              color="warning"
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Collapse in={showingRecents}>
          <QuoteTable
            quoteList={quotes}
            loading={loading}
            setLoading={setLoading}
            addRow={addQuote}
            addedQuotes={quoteList}
          />
        </Collapse>
        <Collapse in={showTextField}>
          <Paper variant="outlined" sx={{ p: 1, my: 2 }}>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                label="Quote Number"
                variant="outlined"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />

              <Button onClick={searchQuote} disabled={!searchText}>
                Search
              </Button>
            </Stack>
            <Collapse in={foundQuote.length > 0 ? true : false}>
              <QuoteTable
                quoteList={foundQuote}
                addRow={addQuote}
                addedQuotes={quoteList}
                disableBloat
              />
            </Collapse>
            <Stack alignItems="flex-end">
              <Button
                variant="outlined"
                color="warning"
                onClick={toggleShowTextField}
              >
                Cancel
              </Button>
            </Stack>
          </Paper>
        </Collapse>
        <Collapse in={quoteList.length > 0}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ my: 1 }}
            align="center"
          >
            Quotes
          </Typography>
          <Stack divider={<Divider light />}>
            {quoteList.map((quote, index) => (
              <QuoteListItem key={index} quote={quote} remove={removeQuote} />
            ))}
          </Stack>
        </Collapse>
        <Collapse in={skipped}>
          <Typography
            variant="h6"
            color="primary"
            align="center"
            sx={{ my: 2 }}
          >
            No quote added, you can proceed to the next step.
          </Typography>
        </Collapse>
      </Paper>
      <MoveStep nextStepAllowed={quoteList.length > 0 || skipped} />
    </>
  );
}

function QuoteListItem({ quote, remove }) {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Card>
      <Paper variant="outlined" sx={{ borderRadius: 0 }}>
        <Stack direction="row">
          <CardActionArea onClick={toggleOpen} sx={{ p: 1 }}>
            <Typography variant="h6">
              {quote.quote_no}{" "}
              <Typography
                variant="overline"
                sx={{ color: (theme) => theme.palette.grey[500] }}
              >
                (click to view details)
              </Typography>
            </Typography>
          </CardActionArea>
          <IconButton
            variant="outlined"
            color="warning"
            onClick={() => remove(quote)}
            startIcon={<CloseIcon />}
          >
            <Delete />
          </IconButton>
        </Stack>
      </Paper>
      <Collapse in={open}>
        <CardContent>
          <Typography variant="h6">Details</Typography>
          <Typography>Company: {quote.company_name}</Typography>
          <Typography>Date Created: {quote.date_created}</Typography>
          <Typography>Date Quoted: {quote.date_quoted}</Typography>
          <Typography>Total: {quote.total}</Typography>
          <Divider sx={{ my: 2 }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Part Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Price EXC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.partNumber}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{NumberFormat(item.price.toFixed(2))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button onClick={toggleOpen}>Hide details</Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => remove(quote)}
            startIcon={<CloseIcon />}
          >
            Remove from Order
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
}

function DetailsArea({
  clientID,
  setClientID,
  quoteClient,
  currency,
  setCurrency,
  orderDetails,
  setOrderDetails,
  logisticDetails,
  setLogisticDetails,
  vatExempt,
  setVatExempt,
  MoveStep,
}) {
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) setCurrency(newAlignment);
  };

  React.useEffect(() => {
    if (clientID) return;
    setClientID(quoteClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Stack spacing={2}>
          <AsyncAutoComplete
            getData={searchClient}
            setValue={setClientID}
            optionLabel="name"
            label="Customers"
            defaultOption={clientID?.name}
          />
          <ToggleButtonGroup
            value={currency}
            onChange={handleChange}
            aria-label="Currency"
            color="info"
            exclusive
          >
            {config.Currencies.map((cur) => (
              <ToggleButton key={cur.value} value={cur.value}>
                {cur.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={vatExempt}
            exclusive
            onChange={(e, newVatExempt) => {
              if (newVatExempt !== null) setVatExempt(newVatExempt);
            }}
            aria-label="vat exempt"
            color="info"
          >
            <ToggleButton value={true} aria-label="vat exempt">
              VAT Exempt
            </ToggleButton>
            <ToggleButton value={false} aria-label="vat not exempt">
              VAT Included
            </ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label="Note to purchasing team"
            value={orderDetails}
            onChange={(e) => setOrderDetails(e.target.value)}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            helperText="Any details for the purchasing team"
          />
          <TextField
            label="Note to logistics team team"
            value={logisticDetails}
            onChange={(e) => setLogisticDetails(e.target.value)}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            helperText="Any details such as delivery time or any special requests from the customer."
          />
        </Stack>
      </Paper>
      <MoveStep nextStepAllowed={clientID && currency} />
    </>
  );
}

function OrderItemArea({
  itemList,
  setItemList,
  quotes,
  hasVat,
  currency,
  MoveStep,
}) {
  const [itemisNotEditing, setItemisNotEditing] = React.useState(true);

  const handleItemEditing = (flag) => {
    setItemisNotEditing(!flag);
  };

  const addCreatedItem = (item) => {
    setItemList((previous) => [
      ...previous,
      {
        po: item.partNumber,
        description: item.description,
        quantity: item.quantity,
        cost: item.price,
        margin_type: "percent",
        margin: 0,
        editing: false,
      },
    ]);
  };

  const addAllItems = (quote) => {
    setItemList((previous) => {
      const newList = quote.items.map((item) => ({
        po: item.partNumber,
        description: item.description,
        quantity: item.quantity,
        cost: item.price,
        margin_type: "percent",
        margin: 0,
        editing: false,
      }));
      return [...previous, ...newList];
    });
  };

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1, maxWidth: "100%" }}>
        <Typography variant="h5" color="primary" sx={{ mb: 1 }}>
          Quotes
        </Typography>
        <Stack spacing={2}>
          {quotes.map((quote, index) => (
            <ItemsAreaQuote
              key={index}
              quote={quote}
              addItem={addCreatedItem}
              addAllItems={() => addAllItems(quote)}
            />
          ))}
        </Stack>
        <ItemsArea
          items={itemList}
          setItems={setItemList}
          setEditingItem={handleItemEditing}
          hasVAT={hasVat}
          currency={currency}
        />
      </Paper>
      <MoveStep nextStepAllowed={itemList.length > 0 && itemisNotEditing} />
    </>
  );
}

function ItemsAreaQuote({ quote, addItem, addAllItems }) {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Card>
      <Typography variant="h6" sx={{ p: 1 }}>
        {quote.quote_no}
      </Typography>
      <Collapse in={open}>
        <CardContent>
          <Typography variant="h6">Details</Typography>
          <Typography>Company: {quote.company_name}</Typography>
          <Typography>Date Created: {quote.date_created}</Typography>
          <Typography>Date Quoted: {quote.date_quoted}</Typography>
          <Typography>Total: {quote.total}</Typography>
          <Divider sx={{ my: 2 }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => addItem(item)}
                      startIcon={<AddIcon />}
                    >
                      Add Item
                    </Button>
                  </TableCell>
                  <TableCell>{item.partNumber}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{NumberFormat(item.price.toFixed(2))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button onClick={toggleOpen}>
          {open ? "Hide Details" : "Show Details"}
        </Button>
        <Button variant="contained" onClick={addAllItems}>
          Add All Items
        </Button>
      </CardActions>
    </Card>
  );
}

function UploadFileArea({ file, setFiles, MoveStep }) {
  const [holder, setHolder] = React.useState(file);
  const [noFiles, setNoFiles] = React.useState(false);

  const handleChange = (files) => {
    setHolder((prev) => [...prev, ...files]);
  };

  return (
    <>
      {!noFiles && (
        <>
          <Typography>Please upload the clients confirmation</Typography>
          <FileUploader handleChange={handleChange} name="file" multiple />
          <List>
            {holder.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setHolder((previous) => {
                        const newList = previous.filter(
                          (item) => item.name !== file.name
                        );
                        return newList;
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </>
      )}

      {holder.length === 0 && (
        <Button onClick={() => setNoFiles(!noFiles)} variant="contained">
          {noFiles ? "Add Files" : "No Files"}
        </Button>
      )}

      <MoveStep
        nextStepAllowed={holder.length > 0 || noFiles}
        onChange={() => setFiles(holder)}
      />
    </>
  );
}

function Finalisation({ data, MoveStep }) {
  // Handling item pricing
  const orderItems = data.items;
  const vatAmount = data.vatExempt ? 1 : config.VAT;
  const total = orderItems.reduce((acc, item) => {
    let totalP;
    if (item.margin_type === "percent") {
      totalP = item.cost * (item.margin / 100 + 1) * item.quantity;
    } else {
      totalP =
        (parseFloat(item.cost) + parseFloat(item.margin)) * item.quantity;
    }
    return acc + totalP * vatAmount;
  }, 0);

  // Get client details
  const [clientDetails, setClientDetails] = React.useState({}),
    [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (loading)
      getClientByID(data.clientID.id).then((res) => {
        setClientDetails(res);
        setLoading(false);
      });
  }, [data.clientID, loading]);

  const OrderLine = ({ name, text }) => {
    return (
      <Grid container item xs={12}>
        <Grid item xs>
          <strong>{name}</strong>
        </Grid>
        <Grid item xs>
          {text}
        </Grid>
      </Grid>
    );
  };

  const currencySymbol = data?.currency === "USD" ? "$" : "KSH";

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1, mb: 2, maxWidth: "100%" }}>
        <Typography variant="h4" align="center" color="primary">
          Please make sure everything is correct before submitting.
        </Typography>
        <Paper sx={{ px: 2, pt: 1 }}>
          <Typography variant="h6" gutterBottom>
            Order Details
          </Typography>
          <Divider />
          <Box sx={{ pl: 2, my: 2 }}>
            <Grid container spacing={2}>
              {loading ? (
                <>
                  <OrderLine name="Company" text={<Skeleton />} />
                  <OrderLine name="Email" text={<Skeleton />} />
                  <OrderLine name="Name" text={<Skeleton />} />
                </>
              ) : (
                <>
                  <OrderLine name="Company" text={clientDetails?.companyName} />
                  <OrderLine name="Email" text={clientDetails?.email} />
                  <OrderLine name="Name" text={clientDetails?.fullName} />
                </>
              )}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <OrderLine name="Currency" text={data.currency} />
              <OrderLine
                name="VAT"
                text={data.vatExempt ? "VAT is Exempt" : "VAT is included"}
              />
            </Grid>
            {data.orderType !== "" && (
              <>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <OrderLine name="Order Type" text={data.orderType} />
                  <OrderLine name="Expected Date" text={data.orderExpected} />
                </Grid>
              </>
            )}
          </Box>

          <Typography variant="h6" gutterBottom>
            Items
          </Typography>
          <Divider />
          <Box sx={{ pl: 2, my: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit Price</TableCell>
                    {data.vatExempt ? (
                      <TableCell>Total Price</TableCell>
                    ) : (
                      <TableCell>Total Price (INC)</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderItems.map((item, index) => {
                    let totalP;
                    if (item.margin_type === "percent") {
                      totalP =
                        item.cost * (item.margin / 100 + 1) * item.quantity;
                    } else {
                      totalP =
                        (parseFloat(item.cost) + parseFloat(item.margin)) *
                        item.quantity;
                    }
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.po}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          {currencySymbol}{" "}
                          {NumberFormat(
                            parseFloat(totalP / item.quantity).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell>
                          {currencySymbol}{" "}
                          {data.vatExempt
                            ? NumberFormat(totalP.toFixed(2))
                            : NumberFormat((totalP * config.VAT).toFixed(2))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            <Divider sx={{ my: 1 }} />

            {!data.vatExempt && (
              <Typography align="right" sx={{ mb: 2, pr: 2 }}>
                <strong>Total before Tax: </strong>
                {currencySymbol} {NumberFormat((total / config.VAT).toFixed(2))}
              </Typography>
            )}

            <Typography align="right" sx={{ mb: 2, pr: 2, pb: 2 }}>
              <strong>Total </strong>
              {currencySymbol} {NumberFormat(total.toFixed(2))}
            </Typography>
          </Box>
        </Paper>
      </Paper>
      <MoveStep nextStepAllowed={true} />
    </>
  );
}

function OrderTypeArea({
  orderType,
  setOrderType,
  orderExpected,
  setOrderExpected,
  MoveStep,
}) {
  const [typeHolder, setTypeHolder] = React.useState(orderType);
  const [expectedHolder, setExpectedHolder] = React.useState(
    new Date(orderExpected) || ""
  );

  React.useEffect(() => {
    setOrderType(typeHolder);
    setOrderExpected(() => {
      if (expectedHolder instanceof Date)
        return expectedHolder.toLocaleDateString();
      return "";
    });
  }, [typeHolder, expectedHolder, setOrderType, setOrderExpected]);

  const [moveNext, setMoveNext] = React.useState(false);

  React.useEffect(() => {
    if (
      typeHolder === "" ||
      (typeHolder !== "" &&
        expectedHolder instanceof Date &&
        !isNaN(expectedHolder))
    )
      setMoveNext(true);
    else setMoveNext(false);
  }, [typeHolder, expectedHolder]);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Collapse in={orderType === null}>
          <Alert severity="warning">
            Please select the order type. This will help us in processing your
            order faster.
          </Alert>
        </Collapse>
        <ToggleButtonGroup
          orientation="vertical"
          exclusive
          onChange={(e, newType) => {
            if (newType !== null) setTypeHolder(newType);
          }}
          value={typeHolder}
        >
          <ToggleButton value="b2b">
            <Timelapse sx={{ mr: 1 }} /> B2B
          </ToggleButton>
          <ToggleButton value="repairs">
            <Handyman sx={{ mr: 1 }} /> Repairs
          </ToggleButton>
          <ToggleButton value="">None of the above</ToggleButton>
        </ToggleButtonGroup>

        <Collapse in={orderType !== "" && orderType !== null}>
          <Alert severity="info" sx={{ mb: 2 }}>
            This is just for our refrence, it will not affect the order in any
            way.
          </Alert>
          <Typography variant="h6" color="primary">
            Expected {orderType === "b2b" ? "Delivery" : "Repair"} Date:
          </Typography>
          <DateTimePicker
            value={expectedHolder}
            setChange={setExpectedHolder}
          />
        </Collapse>
      </Stack>

      <MoveStep nextStepAllowed={moveNext} />
    </>
  );
}
