import React from "react";
import { getTonerByID } from "../API/tonerApi";
import {
  Backdrop,
  Box,
  CardActionArea,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Class, Close, Scale, Straighten } from "@mui/icons-material";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import NumberFormat from "../../../../Components/common/NumberFormater";

export default function IndividualDetails({ tonerID, handleClose }) {
  const [loading, setLoading] = React.useState(true);
  const [toner, setToner] = React.useState(null);

  const buyingPrices = React.useMemo(() => {
    return toner?.history.filter((item) => item.type === false);
  }, [toner]);

  const sellingPrices = React.useMemo(() => {
    return toner?.history.filter(
      (item) =>
        item.type === true && !item.sellout.toLowerCase().includes("faulty")
    );
  }, [toner]);

  const competitivePrices = React.useMemo(() => {
    return toner?.competitive;
  }, [toner]);

  React.useEffect(() => {
    getTonerByID(tonerID).then((res) => {
      if (res.status === "success") {
        setToner(res.data);
      }
      setLoading(false);
    });
  }, [tonerID]);

  if (loading)
    return (
      <Dialog open={true} fullScreen>
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <Close />
          </IconButton>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    );

  return (
    <Dialog open={true} fullScreen>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 6,
          right: 6,
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Class />
          <Typography>{toner.partnumber}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          bgcolor: (theme) => theme.palette.background.default,
        }}
      >
        <Typography variant="overline" gutterBottom>
          {toner.description}
        </Typography>
        <Divider />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item sm={12} md={6}>
            <Chip
              color="success"
              label={`Quantity: ${toner.closing_quantity}`}
              sx={{ mb: 2 }}
            />

            <Stack direction={"column"} spacing={2} sx={{ mb: 1 }}>
              <DataMetrics data={buyingPrices} label={"Buying Prices"} />
              <DataMetrics data={sellingPrices} label={"Selling Prices"} />
              <DataMetrics
                data={competitivePrices}
                label={"Competitive Prices"}
                amountName="price"
              />
              {toner.shipping && (
                <Box
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    p: 1,
                  }}
                >
                  <Stack direction={"row"} spacing={2}>
                    <Chip
                      color="info"
                      icon={<Scale />}
                      label={`Weight: ${toner.shipping.weight} KG`}
                    />
                    <Chip
                      color="info"
                      variant="outlined"
                      icon={<Straighten />}
                      label={`Cubic: ${toner.shipping.cubic.toFixed(2)} M (${
                        toner.shipping.depth
                      } x ${toner.shipping.width} x ${toner.shipping.height})`}
                    />
                  </Stack>
                </Box>
              )}
              {!toner.shipping && (
                <Typography variant="caption" color="textSecondary">
                  No shipping details available
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item sm={12} md={6}>
            <Chart
              buyingRaw={buyingPrices}
              sellingRaw={sellingPrices}
              competitiveRaw={competitivePrices}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item sm={12} md={6}>
            <Chart2
              buyingRaw={buyingPrices}
              sellingRaw={sellingPrices}
              competitiveRaw={competitivePrices}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Chart3
              buyingRaw={buyingPrices}
              sellingRaw={sellingPrices}
              competitiveRaw={competitivePrices}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

function DataMetrics({ data, label, amountName = "cost" }) {
  const [seeBreakdown, setSeeBreakdown] = React.useState(false);

  const comp = amountName !== "cost";

  return (
    <CardActionArea onClick={() => setSeeBreakdown((prev) => !prev)}>
      <Box
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          p: 1,
        }}
      >
        <Typography variant="overline">{label}</Typography>
        <Stack direction={"row"} spacing={2}>
          <Chip
            color="info"
            label={`Avg ${NumberFormat(
              (
                data.reduce((acc, item) => acc + item[amountName], 0) /
                data.length
              ).toFixed(2)
            )}`}
          />
          <Chip
            color="warning"
            variant="outlined"
            label={`Min ${NumberFormat(
              Math.min(...data.map((item) => item[amountName])).toFixed(2)
            )}`}
          />
          <Chip
            color="success"
            variant="outlined"
            label={`Max ${NumberFormat(
              Math.max(...data.map((item) => item[amountName])).toFixed(2)
            )}`}
          />
        </Stack>
        <Collapse in={seeBreakdown} unmountOnExit mountOnEnter>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ mt: 1, maxHeight: 200, overflowY: "auto" }}
          >
            <Typography variant={"caption"}>Date</Typography>
            <Typography variant={"caption"}>
              {comp ? "Group ID" : "Sellout"}
            </Typography>
            {comp ? (
              <Typography variant={"caption"}></Typography>
            ) : (
              <Typography variant={"caption"}>Quantity</Typography>
            )}
            <Typography variant={"caption"}>
              {amountName === "cost" ? "Cost" : "Price"}
            </Typography>
          </Stack>
          <Stack direction={"column"} spacing={1} sx={{ mt: 1 }}>
            {data.map((item, index) => (
              <Stack
                key={index}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant={"caption"}>
                  {new Date(item.date).toLocaleDateString("en-GB")}
                </Typography>
                <Typography variant={"caption"}>
                  {comp ? item.group_id : item.sellout}
                </Typography>
                <Typography variant={"caption"}>{item.quantity}</Typography>
                <Typography variant={"caption"}>
                  {NumberFormat(item[amountName].toFixed(2))}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Box>
    </CardActionArea>
  );
}

function Chart({ buyingRaw, sellingRaw, competitiveRaw }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const dates = React.useMemo(() => {
    let temp = []
      .concat(buyingRaw.map((item) => item.date))
      .concat(sellingRaw.map((item) => item.date))
      .concat(competitiveRaw.map((item) => item.date));
    return Array.from(new Set(temp)).sort((a, b) => new Date(a) - new Date(b));
  }, [buyingRaw, sellingRaw, competitiveRaw]);

  // Fill in gaps for any missing dates in each data set
  const buying = React.useMemo(() => {
    let temp = [];
    dates.forEach((date) => {
      if (!buyingRaw.find((item) => item.date === date)) {
        // If the date is not found we need to fill in the gap using the previous cost, if there is no previous cost we use the next cost
        let prev = [...buyingRaw]
          .reverse()
          .find((item) => new Date(item.date) < new Date(date));
        let next = buyingRaw.find(
          (item) => new Date(item.date) > new Date(date)
        );
        if (prev && next) {
          temp.push({
            date: date,
            cost: null,
          });
          // This is a gap in the data, we need to show that there is no data for this date
        } else if (prev) {
          temp.push({
            date: date,
            cost: prev.cost,
          });
        } else if (next) {
          temp.push({
            date: date,
            cost: next.cost,
          });
        }
      }
    });
    return buyingRaw
      .concat(temp)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [buyingRaw, dates]);

  const selling = React.useMemo(() => {
    let temp = [];
    dates.forEach((date) => {
      if (!sellingRaw.find((item) => item.date === date)) {
        let prev = [...sellingRaw]
          .reverse()
          .find((item) => new Date(item.date) < new Date(date));
        let next = sellingRaw.find(
          (item) => new Date(item.date) > new Date(date)
        );
        if (prev && next) {
          temp.push({
            date: date,
            cost: null,
          });
        } else if (prev) {
          temp.push({
            date: date,
            cost: prev.cost,
          });
        } else if (next) {
          temp.push({
            date: date,
            cost: next.cost,
          });
        }
      }
    });
    return sellingRaw
      .concat(temp)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [sellingRaw, dates]);

  const competitive = React.useMemo(() => {
    let temp = [];
    dates.forEach((date) => {
      if (!competitiveRaw.find((item) => item.date === date)) {
        let prev = [...competitiveRaw]
          .reverse()
          .find((item) => new Date(item.date) < new Date(date));
        let next = competitiveRaw.find(
          (item) => new Date(item.date) > new Date(date)
        );
        if (prev && next) {
          temp.push({
            date: date,
            price: null,
          });
        } else if (prev) {
          temp.push({
            date: date,
            price: prev.price,
          });
        } else if (next) {
          temp.push({
            date: date,
            price: next.price,
          });
        }
      }
    });
    return competitiveRaw
      .concat(temp)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [competitiveRaw, dates]);

  return (
    <Line
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Price History",
          },
        },
      }}
      data={{
        labels: dates.map((date) => new Date(date).toLocaleDateString("en-GB")),
        datasets: [
          {
            label: "Buying Price",
            spanGaps: true,
            data: buying.map((item) => {
              return {
                x: new Date(item.date).toLocaleDateString("en-GB"),
                y: item.cost,
              };
            }),
            fill: "origin",
            borderColor: "rgb(44, 150, 221)",
            backgroundColor: "rgba(44, 150, 221, 0.2)",
            tension: 0.1,
          },
          {
            label: "Selling Price",
            spanGaps: true,
            data: selling.map((item) => {
              return {
                x: new Date(item.date).toLocaleDateString("en-GB"),
                y: item.cost,
              };
            }),
            fill: "+1",
            borderColor: "rgb(84, 235, 54)",
            backgroundColor: "rgba(84, 235, 54, 0.2)",
            tension: 0.1,
          },
          {
            label: "Competitive Price",
            spanGaps: true,
            data: competitive.map((item) => {
              return {
                x: new Date(item.date).toLocaleDateString("en-GB"),
                y: item.price,
              };
            }),
            fill: "-2",
            borderColor: "rgb(221, 40, 40)",
            backgroundColor: "rgba(221, 40, 40, 0.2)",
            tension: 0.1,
          },
        ],
      }}
    />
  );
}

function Chart2({ buyingRaw, sellingRaw, competitiveRaw }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const dates = React.useMemo(() => {
    let temp = []
      .concat(buyingRaw.map((item) => item.date))
      .concat(sellingRaw.map((item) => item.date))
      .concat(competitiveRaw.map((item) => item.date));
    return Array.from(new Set(temp)).sort((a, b) => new Date(a) - new Date(b));
  }, [buyingRaw, sellingRaw, competitiveRaw]);

  // Fill in gaps for any missing dates in each data set
  const buying = React.useMemo(() => {
    let temp = [];
    dates.forEach((date) => {
      if (!buyingRaw.find((item) => item.date === date)) {
        // If the date is not found we need to fill in the gap using the previous cost, if there is no previous cost we use the next cost
        let prev = [...buyingRaw]
          .reverse()
          .find((item) => new Date(item.date) < new Date(date));
        let next = buyingRaw.find(
          (item) => new Date(item.date) > new Date(date)
        );
        if (prev && next) {
          temp.push({
            date: date,
            cost: null,
          });
          // This is a gap in the data, we need to show that there is no data for this date
        } else if (prev) {
          temp.push({
            date: date,
            cost: prev.cost,
          });
        } else if (next) {
          temp.push({
            date: date,
            cost: next.cost,
          });
        }
      }
    });
    return buyingRaw
      .concat(temp)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [buyingRaw, dates]);

  const competitive = React.useMemo(() => {
    let temp = [];
    dates.forEach((date) => {
      if (!competitiveRaw.find((item) => item.date === date)) {
        let prev = [...competitiveRaw]
          .reverse()
          .find((item) => new Date(item.date) < new Date(date));
        let next = competitiveRaw.find(
          (item) => new Date(item.date) > new Date(date)
        );
        if (prev && next) {
          temp.push({
            date: date,
            price: null,
          });
        } else if (prev) {
          temp.push({
            date: date,
            price: prev.price,
          });
        } else if (next) {
          temp.push({
            date: date,
            price: next.price,
          });
        }
      }
    });
    return competitiveRaw
      .concat(temp)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [competitiveRaw, dates]);

  const difference = React.useMemo(() => {
    let lastComp = null;
    let lastBuy = null;
    return buying.map((item) => {
      let comp = competitive.find((comp) => comp.date === item.date);

      // Handles null buy cost
      let currentBuy = item.cost;
      if (currentBuy) lastBuy = currentBuy;
      if (!currentBuy) currentBuy = lastBuy;

      if (comp && comp.price) {
        lastComp = comp;
        return {
          date: item.date,
          cost: comp.price - currentBuy,
        };
      }
      if (lastComp) {
        return {
          date: item.date,
          cost: lastComp.price - currentBuy,
        };
      }
      return {
        date: item.date,
        cost: null,
      };
    });
  }, [buying, competitive]);

  return (
    <Line
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Buying VS Competitive Price",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              color: function (context) {
                if (context.tick.value === 0) {
                  return "rgb(255,0,0)"; // color for the zero line
                }
                return "rgba(0, 0, 0, 0.1)"; // other grid lines
              },
            },
          },
        },
      }}
      data={{
        labels: dates.map((date) => new Date(date).toLocaleDateString("en-GB")),
        datasets: [
          {
            label: "Difference",
            spanGaps: true,
            data: difference.map((item) => {
              return {
                x: new Date(item.date).toLocaleDateString("en-GB"),
                y: item.cost,
              };
            }),
            fill: "origin",
            borderColor: "rgb(84, 235, 54)", // Default green
            backgroundColor: "rgba(84, 235, 54, 0.2)", // Default green area
            segment: {
              borderColor: (context) => {
                if (!context.p1) return "rgb(84, 235, 54)"; // Default color if p1 is undefined
                return context.p1.raw.y >= 0
                  ? "rgb(84, 235, 54)"
                  : "rgb(221, 40, 40)";
              },
              backgroundColor: (context) => {
                if (!context.p1) return "rgba(84, 235, 54, 0.2)"; // Default fill color
                return context.p1.raw.y >= 0
                  ? "rgba(84, 235, 54, 0.2)"
                  : "rgba(221, 40, 40, 0.2)";
              },
            },
            tension: 0.1,
          },
        ],
      }}
    />
  );
}

function Chart3({ sellingRaw, competitiveRaw }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const dates = React.useMemo(() => {
    let temp = []
      .concat(sellingRaw.map((item) => item.date))
      .concat(competitiveRaw.map((item) => item.date));
    return Array.from(new Set(temp)).sort((a, b) => new Date(a) - new Date(b));
  }, [sellingRaw, competitiveRaw]);

  // Fill in gaps for any missing dates in each data set
  const selling = React.useMemo(() => {
    let temp = [];
    dates.forEach((date) => {
      if (!sellingRaw.find((item) => item.date === date)) {
        let prev = [...sellingRaw]
          .reverse()
          .find((item) => new Date(item.date) < new Date(date));
        let next = sellingRaw.find(
          (item) => new Date(item.date) > new Date(date)
        );
        if (prev && next) {
          temp.push({
            date: date,
            cost: null,
          });
        } else if (prev) {
          temp.push({
            date: date,
            cost: prev.cost,
          });
        } else if (next) {
          temp.push({
            date: date,
            cost: next.cost,
          });
        }
      }
    });
    return sellingRaw
      .concat(temp)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [sellingRaw, dates]);

  const competitive = React.useMemo(() => {
    let temp = [];
    dates.forEach((date) => {
      if (!competitiveRaw.find((item) => item.date === date)) {
        let prev = [...competitiveRaw]
          .reverse()
          .find((item) => new Date(item.date) < new Date(date));
        let next = competitiveRaw.find(
          (item) => new Date(item.date) > new Date(date)
        );
        if (prev && next) {
          temp.push({
            date: date,
            price: null,
          });
        } else if (prev) {
          temp.push({
            date: date,
            price: prev.price,
          });
        } else if (next) {
          temp.push({
            date: date,
            price: next.price,
          });
        }
      }
    });
    return competitiveRaw
      .concat(temp)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [competitiveRaw, dates]);

  const difference = React.useMemo(() => {
    let lastComp = null;
    let lastSell = null;
    return selling.map((item) => {
      let comp = competitive.find((comp) => comp.date === item.date);

      // Handles null sell cost
      let currentSell = item.cost;
      if (currentSell) lastSell = currentSell;
      if (!currentSell) currentSell = lastSell;

      if (comp && comp.price) {
        lastComp = comp;
        return {
          date: item.date,
          cost: currentSell - comp.price,
        };
      }
      if (lastComp) {
        return {
          date: item.date,
          cost: currentSell - lastComp.price,
        };
      }
      return {
        date: item.date,
        cost: null,
      };
    });
  }, [selling, competitive]);

  return (
    <Line
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Selling VS Competitive Price",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              color: function (context) {
                if (context.tick.value === 0) {
                  return "rgb(255,0,0)"; // color for the zero line
                }
                return "rgba(0, 0, 0, 0.1)"; // other grid lines
              },
            },
          },
        },
      }}
      data={{
        labels: dates.map((date) => new Date(date).toLocaleDateString("en-GB")),
        datasets: [
          {
            label: "Price Difference",
            spanGaps: true,
            data: difference.map((item) => {
              return {
                x: new Date(item.date).toLocaleDateString("en-GB"),
                y: item.cost,
              };
            }),
            fill: "origin",
            borderColor: "rgb(84, 235, 54)", // Default green
            backgroundColor: "rgba(84, 235, 54, 0.2)", // Default green area
            segment: {
              borderColor: (context) => {
                if (!context.p1) return "rgb(84, 235, 54)"; // Default color if p1 is undefined
                return context.p1.raw.y >= 0
                  ? "rgb(84, 235, 54)"
                  : "rgb(221, 40, 40)";
              },
              backgroundColor: (context) => {
                if (!context.p1) return "rgba(84, 235, 54, 0.2)"; // Default fill color
                return context.p1.raw.y >= 0
                  ? "rgba(84, 235, 54, 0.2)"
                  : "rgba(221, 40, 40, 0.2)";
              },
            },
            tension: 0.1,
          },
        ],
      }}
    />
  );
}
