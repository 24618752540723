import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getInvoice } from "../../API/Cheques&Pos";
import {
  Backdrop,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
  Alert,
  Box,
} from "@mui/material";
import {
  Close,
  Description,
  History,
  Receipt,
  Summarize,
} from "@mui/icons-material";

import { Link } from "react-router-dom";
import NumberFormat from "../../../Components/common/NumberFormater";
import config from "../../Config";
import { toast } from "react-toastify";

export default function SummaryInvoice() {
  const [data, setData] = React.useState({});

  const [loading, setLoading] = React.useState(true);
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const invoiceID = new URLSearchParams(useLocation().search).get("invoiceID");
  const navigator = useNavigate();

  React.useEffect(() => {
    if (invoiceID) {
      getInvoice(invoiceID)
        .then((res) => {
          setData(res);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Failed to fetch invoice", {
            toastId: "invoiceError",
          });
          navigator("/invoice");
        });
    }
  }, [invoiceID, navigator]);

  const addedItems = React.useMemo(() => {
    const items = [];
    structuredClone(data.items)?.forEach((group) => {
      group.items.forEach((item) => {
        if (item.added) {
          items.push(item);
        }
      });
    });
    return items;
  }, [data.items]);

  const total = React.useMemo(() => {
    return addedItems.reduce(
      (total, item) => total + item.unitPrice * item.quantity,
      0
    );
  }, [addedItems]);

  return (
    <Dialog open PaperProps={{ elevation: 0 }} fullScreen maxWidth={"lg"}>
      <EditHistory
        historyOpen={historyOpen}
        setHistoryOpen={setHistoryOpen}
        history={data.history || []}
      />
      {loading ? (
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          }}
        >
          <IconButton
            onClick={() => navigator("/invoice")}
            sx={{
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <Close />
          </IconButton>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <IconButton
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              color: (theme) => theme.palette.grey[500],
              "&:hover": {
                color: (theme) => theme.palette.grey[100],
              },
            }}
            onClick={() => navigator("/invoice")}
          >
            <Close />
          </IconButton>
          <Paper elevation={1} sx={{ p: 1, pl: 2, borderRadius: 0 }}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Summarize />
              <Typography variant="h5">Invoice SI-{data?.invoiceID}</Typography>
            </Stack>
          </Paper>
          <Paper variant="outlined" sx={{ m: 2, p: 2 }}>
            <Typography>
              Date: {new Date(data.date).toDateString()}
              <IconButton variant="info" onClick={() => setHistoryOpen(true)}>
                <History />
              </IconButton>
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography>Client: {data?.clientID.name}</Typography>
            <Typography>Orders:</Typography>
            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ pl: 2, my: 1 }}
            >
              {data?.items.map((order) => (
                <Box key={order.id}>
                  <Chip
                    icon={<Description />}
                    label={order.id}
                    component={Link}
                    to={`/redirect?c=order&i=${order.id}`}
                    target="_blank"
                    sx={{ cursor: "pointer" }}
                    key={order.id}
                  />
                  <Stack
                    direction={"column"}
                    spacing={1}
                    alignItems={"flex-start"}
                    sx={{ pl: 4, my: 1 }}
                  >
                    {data?.confirmations.filter(
                      (conf) => conf.orderID === order.id
                    ).length > 0 ? (
                      data?.confirmations
                        .filter((conf) => conf.orderID === order.id)
                        .map((conf, i) => (
                          <Stack
                            key={`confid${i}`}
                            direction={"row"}
                            spacing={1}
                          >
                            <Chip
                              icon={<Receipt />}
                              label={conf.confirmation_type}
                              color="info"
                              variant="outlined"
                            />
                            <Chip label={conf.details} color="info" />
                          </Stack>
                        ))
                    ) : (
                      <Alert severity="warning">No Confirmations</Alert>
                    )}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Paper>
          <Paper variant="outlined" sx={{ m: 2, p: 2 }}>
            <Typography variant="h6">Totals</Typography>
            <List>
              {addedItems.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText disableTypography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Stack direction={"column"} spacing={0}>
                        <Typography variant="subtitle1">
                          {item.partNumber}
                        </Typography>
                        <Typography variant="caption">
                          {item.description}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography variant="caption">
                          {item.quantity}
                          {" x "}
                          {item.currency}{" "}
                          {NumberFormat(item.unitPrice.toFixed(2))}
                        </Typography>
                        <Divider flexItem />
                        <Typography variant="subtitle1">
                          {NumberFormat(
                            (item.unitPrice * item.quantity).toFixed(2)
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider sx={{ mb: 1 }} />
            <Divider />
            <Stack
              direction="column"
              spacing={1}
              alignItems={"flex-end"}
              sx={{ my: 1 }}
            >
              <Typography variant="body1">
                Total Exc: {NumberFormat(total.toFixed(2))}
              </Typography>
              <Typography variant="body1">
                Vat: {NumberFormat((total * config.VAT - total).toFixed(2))}
              </Typography>
              <Typography variant="body1">
                Total Inc: {NumberFormat((total * config.VAT).toFixed(2))}
              </Typography>
            </Stack>
          </Paper>
        </>
      )}
    </Dialog>
  );
}

const EditHistory = ({ historyOpen, setHistoryOpen, history }) => {
  return (
    <Dialog open={historyOpen} onClose={() => setHistoryOpen(false)}>
      <Paper elevation={0}>
        {history.length === 0 ? (
          <Typography sx={{ p: 3 }}>No History</Typography>
        ) : (
          <Table>
            <TableBody>
              {history.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{new Date(item.date).toDateString()}</TableCell>
                  <TableCell>
                    {new Date(item.date).toLocaleTimeString()}
                  </TableCell>
                  <TableCell>
                    {item.status} by {item.user}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </Dialog>
  );
};
