import React from "react";
import { Box, Chip, Popover, Tooltip, Typography } from "@mui/material";
import { Description, NoSim } from "@mui/icons-material";

export default function DescriptionBubble({
  bubbleID,
  text = "",
  nullText = "-",
  disablePreview = false,
  displayTextLength = 10,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const descOpen = Boolean(anchorEl);
  const id = descOpen ? bubbleID : undefined;

  let displayText = text || nullText;

  const onHover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Tooltip title={displayText}>
        <Chip
          label={
            displayText.length > displayTextLength
              ? `${displayText.slice(0, displayTextLength)}...`
              : displayText
          }
          variant="outlined"
          icon={displayText === nullText ? <NoSim /> : <Description />}
          onClick={onHover}
          sx={{ p: 1 }}
          color={descOpen ? "info" : "default"}
        />
      </Tooltip>
      <Popover
        id={id}
        open={descOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">{displayText}</Typography>
        </Box>
      </Popover>
    </>
  );
}
