import { Box, Stack } from "@mui/material";
import React from "react";

export default function FloatFormGroup({ children }) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 8,
        right: 8,
        zIndex: 1000,
      }}
    >
      <Stack direction={"column"} spacing={2} alignItems={"flex-end"}>
        {children}
      </Stack>
    </Box>
  );
}
