import React from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import IndividualDetails from "../Components/IndividualDetails";

export default function DetailsPage() {
  const urlDets = new URLSearchParams(useLocation().search);
  const id = urlDets.get("id");

  const { origin } = useOutletContext();

  let browseHistory = useNavigate();
  const goBack = React.useCallback(() => {
    browseHistory(origin);
  }, [browseHistory, origin]);

  return <IndividualDetails tonerID={id} handleClose={goBack} />;
}
