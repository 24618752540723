import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Paper,
  TextField,
  Collapse,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  InputAdornment,
  Backdrop,
  ToggleButtonGroup,
  ToggleButton,
  CardActionArea,
  CircularProgress,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

import { checkChequeNumber } from "../../API/Cheques&Pos";
import RemoveIcon from "@mui/icons-material/Remove";
import { TransitionGroup } from "react-transition-group";
import {
  Add,
  CheckCircleOutline,
  CreditCard,
  ErrorOutline,
  Remove,
} from "@mui/icons-material";

export default function ChequeArea({
  cheques,
  setCheques,
  amount = 0,
  currency,
  isCashCheque = false,
  poNumber,
}) {
  const [cCount, setCCount] = React.useState(1);

  const addCheque = () => {
    const cAmount = (parseFloat(amount) / cCount).toFixed(2);
    const newCheques = [...cheques];

    for (let i = 0; i < cCount; i++) {
      newCheques.push({
        id: Date.now() + i,
        number: "",
        amount: cAmount,
        valid: false,
        bank: "I&M",
        duplicate: false,
      });
    }
    setCheques(newCheques);
    setCCount(1);
  };

  const removeCheque = (id) => {
    setCheques((previous) => {
      previous = previous.filter((cheque) => cheque.id !== id);
      return [...previous];
    });
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Stack direction="row" spacing={0}>
        <ToggleButtonGroup
          orientation="vertical"
          sx={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            bgcolor: (theme) => theme.palette.secondary.main,
          }}
        >
          <ToggleButton
            onClick={() => setCCount((previous) => previous + 1)}
            sx={{ borderTopRightRadius: 0 }}
          >
            <Add />
          </ToggleButton>
          <ToggleButton
            onClick={() => {
              if (cCount > 1) setCCount((previous) => previous - 1);
            }}
            disabled={cCount === 1}
            sx={{ borderBottomRightRadius: 0 }}
          >
            <Remove />
          </ToggleButton>
        </ToggleButtonGroup>

        <CardActionArea
          onClick={addCheque}
          sx={{
            borderRadius: "0 6px 6px 0",
            border: (theme) => `1px solid ${theme.palette.divider}`,
            width: "fit-content",
            p: 2,
            bgcolor: (theme) => theme.palette.secondary.main,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <CreditCard />{" "}
            <Typography>
              Add {cCount} cheque{cCount > 1 && "s"}
            </Typography>
          </Stack>
        </CardActionArea>
      </Stack>

      {cheques.length === 0 ? (
        <Paper sx={{ p: 2, mt: 1 }}>
          <Typography variant="overline" sx={{ width: "100%" }}>
            No cheques added
          </Typography>
        </Paper>
      ) : (
        <TransitionGroup style={{ width: "100%" }}>
          {cheques.map((cheque, index) => (
            <Collapse key={cheque.id}>
              <Cheque
                key={cheque.id}
                index={index}
                data={cheque}
                setCheque={setCheques}
                removeSelf={removeCheque}
                cheques={cheques}
                currency={currency}
                isCashCheque={isCashCheque}
                poNumber={poNumber}
              />
            </Collapse>
          ))}
        </TransitionGroup>
      )}
    </Box>
  );
}

function Cheque({
  data,
  index,
  removeSelf,
  setCheque,
  cheques,
  currency,
  isCashCheque,
  poNumber,
}) {
  const [number, setNumber] = React.useState(data.number);
  const [amount, setAmount] = React.useState(data.amount);
  const [bank, setBank] = React.useState(data.bank);

  const [sameAsPO, setSameAsPO] = React.useState(false);

  const handleDelete = () => {
    if (data.exists) {
      setCheque((previous) => {
        previous[index].cancelled = true;
        return [...previous];
      });
      return;
    }
    removeSelf(data.id);
  };

  const undoCancel = () => {
    setCheque((previous) => {
      const cheque = previous.find((cheque) => cheque.id === data.id);
      cheque.cancelled = false;
      return [...previous];
    });
  };

  // check for duplicates
  React.useEffect(() => {
    const markAsDuplicate = (duplicate) => {
      setCheque((previous) => {
        const cheque = previous.find((cheque) => cheque.id === data.id);
        cheque.duplicate = duplicate;
        return [...previous];
      });
    };

    const numberCount = cheques.filter(
      (cheque) => cheque.number === data.number && cheque.bank === data.bank
    ).length;
    const isCopy = data.duplicate ? true : false;

    if (numberCount > 1 && isCopy === false) markAsDuplicate(true);
    if (numberCount === 1 && isCopy === true) markAsDuplicate(false);
  }, [cheques, data.number, setCheque, data.duplicate, data.id, data.bank]);

  // determine if the cheque number is already present in the backend
  const [checkingIndicator, setCheckingIndicator] = React.useState(false);
  const [checkComplete, setCheckComplete] = React.useState(false);

  React.useEffect(() => {
    const validate = (valid) => {
      setCheckComplete(false);
      setCheckingIndicator(true);
      setCheque((previous) => {
        const cheque = previous.find((cheque) => cheque.id === data.id);
        cheque.valid = valid;
        return [...previous];
      });
      setTimeout(() => {
        setCheckComplete(true);
      }, 1500);
      setTimeout(() => {
        setCheckingIndicator(false);
      }, 2000);
    };

    if (data.number === poNumber) setSameAsPO(true);

    let valid;
    validate(false);
    if (data.number === "") {
      return;
    }

    if (data.number === data.previous) {
      validate(true);
      return;
    }

    const checkValid = () => {
      checkChequeNumber({
        number: data.number,
        bank: data.bank,
        currency: currency,
      })
        .then((res) => {
          validate(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    valid = setTimeout(checkValid, 700);

    return () => {
      clearTimeout(valid);
    };
  }, [
    data.id,
    data.number,
    data.bank,
    data.previous,
    setCheque,
    currency,
    poNumber,
  ]);

  React.useEffect(() => {
    const editCheque = (id, number, amount) => {
      setCheque((previous) => {
        const cheque = previous.find((cheque) => cheque.id === id);
        cheque.number = number;
        cheque.amount = amount;
        cheque.bank = bank;
        return [...previous];
      });
    };
    const editField = setTimeout(editCheque, 300, data.id, number, amount);
    return () => {
      clearTimeout(editField);
    };
  }, [number, amount, bank, data, setCheque]);

  return (
    <>
      <Backdrop open={sameAsPO} sx={{ zIndex: 1000 }}>
        <Alert
          severity="warning"
          sx={{ mt: 1 }}
          onClose={() => setSameAsPO(false)}
        >
          <AlertTitle>Check Number Issue</AlertTitle>
          Cheque number is the same as the PO number
        </Alert>
      </Backdrop>
      {data.cancelled ? (
        <Paper
          variant="outlined"
          sx={{
            mt: 1,
          }}
        >
          <Alert
            severity="warning"
            action={
              <Button color="inherit" onClick={undoCancel}>
                Undo
              </Button>
            }
          >
            <AlertTitle>Cheque {data.number} has been Cancelled</AlertTitle>
          </Alert>
        </Paper>
      ) : (
        <Paper
          variant="outlined"
          sx={{
            mt: 1,
            ...((number === "" || !data.valid || data.duplicate) && {
              border: (theme) => `1px solid ${theme.palette.error.main}`,
            }),
          }}
        >
          <Stack spacing={2} direction="row" sx={{ my: 2 }} alignItems="center">
            <IconButton
              onClick={handleDelete}
              color="secondary"
              sx={{ height: "fit-content" }}
            >
              <RemoveIcon />
            </IconButton>
            <TextField
              label="Cheque Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              type="number"
              onWheel={(e) => e.target.blur()}
              error={number === "" || !data.valid || data.duplicate}
              helperText={
                number === "" || !data.valid || data.duplicate
                  ? "This cheque has already been used"
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Collapse
                      in={checkingIndicator}
                      orientation="horizontal"
                      timeout={{
                        appear: 200,
                        enter: 200,
                        exit: 700,
                      }}
                      unmountOnExit
                    >
                      {checkComplete ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <CircularProgress size={"1rem"} />
                      )}
                    </Collapse>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction="row" spacing={1}>
                      {isCashCheque && (
                        <Chip
                          label="Cash"
                          color="info"
                          icon={<ErrorOutline />}
                        />
                      )}
                      {currency === "USD" ? (
                        <Chip label="USD" variant="outlined" color="warning" />
                      ) : (
                        <Chip label="KES" variant="outlined" color="success" />
                      )}
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Amount"
              value={amount}
              type="number"
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setAmount(e.target.value)}
            />
            <FormControl>
              <InputLabel>Bank</InputLabel>
              <Select
                value={bank}
                onChange={(e) => setBank(e.target.value)}
                label="Bank"
              >
                <MenuItem value="I&M">I&M</MenuItem>
                <MenuItem value="NCBA">NCBA</MenuItem>
                <MenuItem value="Paramount">Paramount</MenuItem>
                <MenuItem value="Equity">Equity</MenuItem>
                <MenuItem value="Sidian">Sidian</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Paper>
      )}
    </>
  );
}
