import React from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Slide,
  Stack,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import DateTimePicker from "../common/DateTimePicker";
import { LoadingButton } from "@mui/lab";
import {
  checkInvoiceNumber,
  get_recent_customer_invoices,
} from "../../API/Cheques&Pos";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import { searchClientsAdmin } from "../../API/SalesAdmin";
import ItemInfo from "../common/ItemInfo";
import { TransitionGroup } from "react-transition-group";
import {
  Add,
  ArrowDownward,
  Create,
  ExpandMore,
  Remove,
  Summarize,
} from "@mui/icons-material";
import NumberFormater from "../../../Components/common/NumberFormater";
import DescriptionBubble from "../common/DescriptionBubble";
import NumberFormat from "../../../Components/common/NumberFormater";
import config from "../../Config";
import { useInView } from "react-intersection-observer";

export default function CreateInvoice({
  isCustomer = true,
  onSubmit,
  edit = false,
  data = {},
}) {
  const [invoiceID, setInvoiceID] = React.useState(data?.invoiceID || ""),
    [invoiceIDisValid, setInvoiceIDisValid] = React.useState(false),
    [invoiceDate, setInvoiceDate] = React.useState(
      data?.date ? new Date(data?.date) : new Date()
    ),
    [currency, setCurrency] = React.useState(data?.currency || "KES"),
    [orderItems, setOrderItems] = React.useState(
      structuredClone(data.items) || []
    ),
    [refrenceIDS, setRefrenceIDS] = React.useState([]),
    [formIsValid, setFormIsValid] = React.useState(false),
    [clientID, setClientID] = React.useState(data?.clientID),
    [loading, setLoading] = React.useState(false);

  const addOrder = (newOrder) => {
    const present = orderItems.some((order) => order.id === newOrder.id);
    if (!present) {
      setOrderItems((prev) => [...prev, structuredClone(newOrder)]);
      setClientID(newOrder.client);
    }
  };

  const addedItems = React.useMemo(() => {
    const items = [];
    orderItems.forEach((group) => {
      group.items.forEach((item) => {
        if (item.added) {
          items.push(item);
        }
      });
    });
    return items;
  }, [orderItems]);

  const itemsMap = React.useMemo(() => {
    if (!edit) return [];
    const map = {};
    let length = 0;
    data.items.forEach((group) => {
      group.items.forEach((item) => {
        if (item.added) {
          map[item.id] = { quantity: item.quantity };
          length += 1;
        }
      });
    });
    map.length = length;

    return map;
  }, [data.items, edit]);

  const editValid = React.useMemo(() => {
    if (!edit) return true;
    if (invoiceID !== data.invoiceID) return true;
    if (currency !== data.currency) return true;
    let changed = false;

    let originalItems = 0;

    for (let i = 0; i < addedItems.length; i += 1) {
      const previousItem = itemsMap[addedItems[i].id];

      if (previousItem) originalItems += 1;

      if (!previousItem || previousItem.quantity !== addedItems[i].quantity)
        return true;
    }

    if (
      addedItems.length !== itemsMap.length ||
      originalItems !== itemsMap.length
    )
      return true;

    return changed;
  }, [
    addedItems,
    edit,
    itemsMap,
    invoiceID,
    data.invoiceID,
    data.currency,
    currency,
  ]);

  const onComplete = () => {
    const data = {
      invoiceID,
      date: invoiceDate,
      items: addedItems,
      currency,
      isCustomer,
    };
    setLoading(true);
    onSubmit(data).finally(() => setLoading(false));
  };

  const changeClientID = (id) => {
    setOrderItems([]);
    setClientID(id);
  };

  React.useEffect(() => {
    if (
      invoiceIDisValid &&
      addedItems.length > 0 &&
      (editValid ||
        new Date(data?.date).toLocaleString() !== invoiceDate.toLocaleString())
    ) {
      setFormIsValid(true);
    } else setFormIsValid(false);
  }, [invoiceIDisValid, addedItems, editValid, data?.date, invoiceDate]);

  const total = React.useMemo(() => {
    return addedItems.reduce(
      (total, item) => total + item.unitPrice * item.quantity,
      0
    );
  }, [addedItems]);

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const scrollRef = React.useRef();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ p: 2, mx: "auto" }}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <InvoiceIDTextFeild
              invoiceID={invoiceID}
              setInvoiceID={setInvoiceID}
              valid={invoiceIDisValid}
              setValid={setInvoiceIDisValid}
              currentID={data?.invoiceID}
            />
            <DateTimePicker
              label="Invoice Date"
              value={invoiceDate}
              setChange={setInvoiceDate}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ my: 2 }}
          >
            <ToggleButtonGroup
              value={currency}
              exclusive
              onChange={(e, value) => {
                if (value !== null) setCurrency(value);
              }}
            >
              <ToggleButton value="KES">KES</ToggleButton>
              <ToggleButton value="USD">USD</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>
        <Collapse in={invoiceIDisValid}>
          <Divider />
          <Box sx={{ p: 2, pb: 0 }}>
            <Typography variant="h6" color="primary" gutterBottom>
              {isCustomer ? "Customer" : "Supplier"} Details
            </Typography>
            {isCustomer ? (
              <>
                <Box sx={{ mb: 1 }}>
                  <AsyncAutoComplete
                    getData={searchClientsAdmin}
                    setValue={changeClientID}
                    optionLabel="name"
                    label="Customers"
                    defaultOption={clientID}
                  />
                  {clientID && (
                    <Alert severity="warning">
                      Changing the customer will clear all added Orders
                    </Alert>
                  )}
                </Box>
                <SearchItems
                  clientID={clientID}
                  addOrder={addOrder}
                  orderItems={orderItems}
                />
              </>
            ) : (
              <PickPOSItems
                refrenceIDS={refrenceIDS}
                setRefrenceIDS={setRefrenceIDS}
              />
            )}
          </Box>
        </Collapse>
        <Collapse in={orderItems.length > 0}>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" color="primary" gutterBottom>
              Invoice Items
            </Typography>
            <TransitionGroup>
              {orderItems.map((order) => (
                <Collapse key={order.id}>
                  <PickedItemsDisplay
                    order={order}
                    setOrderItems={setOrderItems}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </Box>
        </Collapse>
        <Collapse in={addedItems.length > 0}>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Summarize />
              <Typography variant="h5" gutterBottom>
                Invoice Summary
              </Typography>
            </Stack>

            <List>
              {addedItems.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText disableTypography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Stack direction={"column"} spacing={0}>
                        <Typography variant="subtitle1">
                          {item.partNumber}
                        </Typography>
                        <Typography variant="caption">
                          {item.description}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography variant="caption">
                          {item.quantity}
                          {" x "}
                          {item.currency}{" "}
                          {NumberFormat(item.unitPrice.toFixed(2))}
                        </Typography>
                        <Divider flexItem />
                        <Typography variant="subtitle1">
                          {NumberFormater(
                            (item.unitPrice * item.quantity).toFixed(2)
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider sx={{ mb: 1 }} />
            <Divider />
            <Stack
              direction="column"
              spacing={1}
              alignItems={"flex-end"}
              sx={{ my: 1 }}
            >
              <Typography variant="body1">
                Total Exc: {NumberFormat(total.toFixed(2))}
              </Typography>
              <Typography variant="body1">
                Vat: {NumberFormat((total * config.VAT - total).toFixed(2))}
              </Typography>
              <Typography variant="body1">
                Total Inc: {NumberFormat((total * config.VAT).toFixed(2))}
              </Typography>
            </Stack>
          </Box>
        </Collapse>

        {formIsValid && (
          <Slide in={!inView} direction="up">
            <Fab
              sx={{ position: "fixed", bottom: 16, right: 16 }}
              onClick={() => {
                //Scroll to ref to show the finalise button
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }}
              variant="extended"
              color="info"
            >
              Finalise Invoice
              <ArrowDownward />
            </Fab>
          </Slide>
        )}

        <Slide
          in={formIsValid}
          direction="up"
          ref={ref}
          mountOnEnter
          unmountOnExit
        >
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"flex-end"}
            sx={{ mr: 2, mb: 2 }}
            ref={scrollRef}
          >
            <LoadingButton
              variant="contained"
              loading={loading}
              startIcon={<Create />}
              onClick={onComplete}
            >
              {edit ? "Update Invoice" : "Create Invoice"}
            </LoadingButton>
          </Stack>
        </Slide>
      </Box>
    </Box>
  );
}

function InvoiceIDTextFeild({
  invoiceID,
  setInvoiceID,
  valid,
  setValid,
  currentID,
}) {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let validate;
    if (currentID === invoiceID) {
      setValid(true);
      return;
    }
    const checkValid = async () => {
      setLoading(true);
      checkInvoiceNumber(invoiceID, true)
        .then((res) => {
          if (res.invoiceValidity === true) setValid(true);
          else setValid(false);
        })
        .finally(() => setLoading(false));
    };
    if (invoiceID === "") setValid(false);
    else validate = setTimeout(checkValid, 500);
    return () => clearTimeout(validate);
  }, [invoiceID, setValid, currentID]);

  return (
    <TextField
      label="Invoice Number"
      value={invoiceID}
      onChange={(e) => setInvoiceID(e.target.value)}
      color={valid && !loading ? "success" : "primary"}
      focused={valid && !loading}
      error={!valid}
      helperText={
        invoiceID === ""
          ? ""
          : valid
          ? "Invoice ID is Valid"
          : "Invoice Number Exists"
      }
      type="number"
      onWheel={(e) => e.target.blur()}
      InputProps={{
        endAdornment: (
          <Collapse
            in={loading}
            orientation={"horizontal"}
            timeout={{
              appear: 0,
              enter: 100,
              exit: 700,
            }}
          >
            <CircularProgress color="info" size={20} />
          </Collapse>
        ),
      }}
      required
    />
  );
}

function PickPOSItems({ items, setItems, refrenceIDS, setRefrenceIDS }) {
  return <></>;
}

function SearchItems({ clientID, addOrder, orderItems }) {
  const [partialHolder, setPartialHolder] = React.useState(""),
    [partialOrderID, setPartialOrderID] = React.useState(""),
    [data, setData] = React.useState([]),
    [loading, setLoading] = React.useState(false);

  const viewableGroups = data.filter(
    (group) =>
      !orderItems.some((orderItem) => orderItem.id === group.id) &&
      (group.client.id === clientID?.id || !clientID)
  );

  // change so fetch request function is gotten from prop
  React.useEffect(() => {
    setLoading(true);
    get_recent_customer_invoices({
      clientID: clientID?.id || "",
      partialOrderID: partialOrderID,
    })
      .then((res) => {
        setData(res);
      })
      .finally(() => setLoading(false));
  }, [clientID, partialOrderID]);

  React.useEffect(() => {
    const timeID = setTimeout(() => {
      setPartialOrderID(partialHolder);
    }, 1000);
    return () => clearTimeout(timeID);
  }, [partialHolder]);

  return (
    <Stack spacing={2}>
      <TextField
        label="Order ID"
        value={partialHolder}
        onChange={(e) => setPartialHolder(e.target.value)}
        InputProps={{
          endAdornment: (
            <Collapse
              in={loading}
              orientation={"horizontal"}
              timeout={{
                appear: 0,
                enter: 100,
                exit: 700,
              }}
            >
              <CircularProgress color="info" size={20} />
            </Collapse>
          ),
        }}
      />
      <Collapse in={data.length > 0}>
        <TransitionGroup>
          {viewableGroups.map((order) => (
            <Collapse key={order.id}>
              <PickItemDisplay order={order} addOrder={addOrder} />
            </Collapse>
          ))}
        </TransitionGroup>
      </Collapse>
      <Collapse in={data.length === 0}>
        <Box
          display="flex"
          justifyContent={"center"}
          flexDirection={"column"}
          sx={{ p: 2 }}
        >
          <Divider />
          <Typography variant="overline" align="center">
            No Orders found
          </Typography>
          <Divider />
        </Box>
      </Collapse>
    </Stack>
  );
}

function PickItemDisplay({ order, addOrder }) {
  const [open, setOpen] = React.useState(true);

  return (
    <Collapse in={true}>
      <Card
        sx={{
          mb: 1,
        }}
      >
        <CardActionArea sx={{ p: 1 }} onClick={() => setOpen((prev) => !prev)}>
          <Stack
            direction={"row"}
            spacing={1}
            alignContent="center"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={1} alignItems="center">
              <Typography variant="subtitle1">{order.id}</Typography>
              <Chip label={order.currency} color="info" size="small" />
              <Typography variant="subtitle1">
                {NumberFormater(
                  order.items
                    .reduce(
                      (total, item) => total + item.unitPrice * item.quantity,
                      0
                    )
                    .toFixed(2)
                )}
              </Typography>
            </Stack>
            <Stack direction={"row"} spacing={1} alignItems="center">
              <Button
                variant={"contained"}
                color={"success"}
                onClick={(e) => {
                  e.stopPropagation();
                  addOrder(order);
                }}
                startIcon={<Add />}
              >
                Add
              </Button>

              <SvgIcon
                sx={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: (theme) => theme.transitions.create("transform"),
                }}
              >
                <ExpandMore />
              </SvgIcon>
            </Stack>
          </Stack>
        </CardActionArea>
        <Collapse in={open}>
          <Paper variant="outlined" sx={{ borderRadius: 0, p: 1 }}>
            <Grid container spacing={1}>
              {order.items.map((item) => (
                <Item
                  key={`${item.id}`}
                  mainID={item.id}
                  id={item.id}
                  partNumber={item.partNumber}
                  quantity={item.quantity}
                  currency={order.currency}
                  unitPrice={item.unitPrice}
                  description={item.description}
                  added={item.added} //check if the item is added to the invoiceItems.}
                />
              ))}
            </Grid>
          </Paper>
        </Collapse>
      </Card>
    </Collapse>
  );
}

function PickedItemsDisplay({ order, setOrderItems }) {
  const [open, setOpen] = React.useState(true);

  const changeAdded = (id, itemID, value) => {
    // make sure that there are no duplicates
    setOrderItems((prev) => {
      const targetObject = prev.find((object) => object.id === id);
      if (!targetObject) return prev;

      const targetItem = targetObject.items.find((item) => item.id === itemID);
      if (targetItem) targetItem.added = value;

      return [...prev];
    });
  };

  const changeProperty = (property, value, id) => {
    setOrderItems((prev) => {
      const targetObject = prev.find((object) => object.id === order.id);
      if (!targetObject) return prev;

      const target = targetObject.items.find((item) => item.id === id);
      if (target) target[property] = value;
      return [...prev];
    });
  };
  return (
    <Collapse in={true}>
      <Divider />
      <Card sx={{ mb: 1 }}>
        <CardActionArea
          sx={{ p: 1 }}
          onClick={(e) => {
            setOpen((prev) => !prev);
          }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            alignContent="center"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={1} alignContent="center">
              <Typography variant="subtitle1">{order.id}</Typography>
              <Chip label={order.currency} color="info" size="small" />
            </Stack>
            <Stack direction={"row"} spacing={1} alignContent="center">
              <Button
                variant={"outlined"}
                color={"warning"}
                startIcon={<Remove />}
                onClick={(e) => {
                  e.stopPropagation();
                  // trigger an are you sure prompt before removing order
                  setOrderItems((prev) =>
                    prev.filter((value) => order.id !== value.id)
                  );
                }}
              >
                Remove
              </Button>
              <SvgIcon
                sx={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: (theme) => theme.transitions.create("transform"),
                }}
              >
                <ExpandMore />
              </SvgIcon>
            </Stack>
          </Stack>
        </CardActionArea>
        <Collapse in={open}>
          <Paper variant="outlined" sx={{ borderRadius: 0, p: 1 }}>
            <Grid container spacing={1}>
              {order.items.map((item) => (
                <Item
                  key={item.id}
                  mainID={order.id}
                  id={item.id}
                  partNumber={item.partNumber}
                  quantity={item.quantity}
                  currency={order.currency}
                  unitPrice={item.unitPrice}
                  description={item.description}
                  changeQuantity={changeProperty}
                  changeAdded={changeAdded}
                  added={item.added}
                />
              ))}
            </Grid>
          </Paper>
        </Collapse>
      </Card>
    </Collapse>
  );
}

function Item({
  mainID,
  id,
  partNumber,
  currency,
  unitPrice,
  quantity,
  description,
  added,
  changeAdded,
  changeQuantity,
}) {
  return (
    <Grid item xs={12} md={6} lg={4} sx={{}}>
      <Card
        sx={{
          p: 1,
          border: (theme) => added && `2px solid ${theme.palette.success.main}`,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="subtitle2">{partNumber}</Typography>
            <Typography variant="body1">
              {currency} {NumberFormater(unitPrice.toFixed(2))}
              {changeQuantity ? (
                <ItemInfo
                  property="quantity"
                  name="Quantity"
                  value={quantity}
                  setValue={() => null} // check if there is any situation where setValue is useful
                  onComplete={changeQuantity}
                  type="number"
                  id={id}
                  styleProps={{ width: "fit-content" }}
                />
              ) : (
                ` x QTY ${quantity}`
              )}
            </Typography>
            <DescriptionBubble
              bubbleID={`${mainID}_item_${id}`}
              text={description}
              displayTextLength={40}
            />
          </Box>
          {changeAdded && (
            <Button
              sx={{ ml: 1 }}
              variant={added ? "outlined" : "contained"}
              color={added ? "warning" : "success"}
              startIcon={added ? <Remove /> : <Add />}
              onClick={() => changeAdded(mainID, id, !added)}
            >
              {added ? "Remove" : "Add"}
            </Button>
          )}
        </Stack>
      </Card>
    </Grid>
  );
}
