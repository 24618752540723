import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { getInvoice, editInvoice } from "../../API/Cheques&Pos";
import CreateInvoice from "./CreateInvoice";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import AreYouSure from "../../../Components/common/AreYouSure";
import { Close } from "@mui/icons-material";

export default function EditInvoice() {
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const loadFiltered = useOutletContext();

  const [loading, setLoading] = React.useState(true);
  const invoiceID = new URLSearchParams(useLocation().search).get("invoiceID");
  const navigator = useNavigate();

  React.useEffect(() => {
    if (invoiceID) {
      getInvoice(invoiceID)
        .then((res) => {
          setData(res);
        })
        .finally(() => setLoading(false));
    }
  }, [invoiceID]);

  return (
    <Dialog open PaperProps={{ elevation: 0 }} fullScreen maxWidth={"lg"}>
      {loading ? (
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          }}
        >
          <IconButton
            onClick={() => navigator("/invoice")}
            sx={{
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <Close />
          </IconButton>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <AreYouSure
            open={open}
            onAccept={() => {
              navigator("/invoice");
            }}
            onDecline={() => setOpen(false)}
          />
          <IconButton
            sx={{
              position: "fixed",
              right: 16,
              color: (theme) => theme.palette.grey[500],
              "&:hover": {
                color: (theme) => theme.palette.grey[100],
              },
            }}
            onClick={() => setOpen(true)}
          >
            <CloseIcon />
          </IconButton>
          <Paper elevation={1} sx={{ p: 1, pl: 2, borderRadius: 0 }}>
            <Typography variant="h5" color="primary">
              Edit Invoice
            </Typography>
          </Paper>

          <CreateInvoice
            edit={true}
            data={data}
            onSubmit={async (editData) => {
              editData.id = data.id;
              const response = await editInvoice(editData);
              if (response.status === "success") {
                const data = await getInvoice(invoiceID);
                setData(data);
                toast.success(response.message);
                loadFiltered();
                navigator("/invoice");
              } else toast.error(response.message);
            }}
          />
        </>
      )}
    </Dialog>
  );
}
