import React from "react";

import {
  editOverviewConfirmation,
  getAdminOrderConfirmations,
} from "../../API/Cheques&Pos";
import {
  Alert,
  Button,
  CardActionArea,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  Close,
  Delete,
  ExpandMore,
  Restore,
  Save,
} from "@mui/icons-material";

import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CheckRepeatConfirmation from "../common/CheckRepeatConfirmation";
import TransitionGroup from "react-transition-group/TransitionGroup";
import NumberFormat from "../../../Components/common/NumberFormater";
import DateTimePicker from "./DateTimePicker";

export default function AddConfirmation({
  orderID,
  onChangesSaved,
  reloadWarning = false,
}) {
  const [addConf, setAddConf] = React.useState(false);
  const [changes, setChanges] = React.useState(false);

  const handleChangesSaved = () => {
    setChanges(true);
    onChangesSaved();
  };

  return (
    <>
      <Collapse in={changes} timeout="auto" unmountOnExit>
        <Alert severity="info">Confirmations have been updated</Alert>
      </Collapse>

      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={() => setAddConf(true)}
        color="inherit"
      >
        Add Confirmation
      </Button>
      <ManageConfrimations
        open={addConf}
        setOpen={setAddConf}
        orderID={orderID}
        onChangesSaved={handleChangesSaved}
        reloadWarning={reloadWarning}
      />
    </>
  );
}

function ManageConfrimations({
  open,
  setOpen,
  orderID,
  onChangesSaved,
  reloadWarning,
}) {
  const [loading, setLoading] = React.useState(true);

  const [showAdd, setShowAdd] = React.useState(false);
  const [confirmationValue, setConfirmationValue] = React.useState("");
  const [confirmationType, setConfirmationType] = React.useState("");

  const [confirmationChecked, setConfirmationChecked] = React.useState(false);
  const [checkingConfirmation, setCheckingConfirmation] = React.useState(false);

  const [checkValue, setCheckValue] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [disableAdd, setDisableAdd] = React.useState(true);
  const [confirmations, setConfirmations] = React.useState([]);
  const [currentConfirmations, setCurrentConfirmations] = React.useState([]);
  const [clientID, setClientID] = React.useState("");
  const [changed, setChanged] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (loading)
      getAdminOrderConfirmations(orderID)
        .then((data) => {
          setClientID(data.clientID);
          setCurrentConfirmations(data.confirmations);
          setConfirmations((prev) => {
            let newObject = [...prev];
            data.confirmations.forEach((confirmation) => {
              if (!newObject.some((i) => i.value === confirmation.value))
                newObject.push(confirmation);
            });
            return newObject;
          });
        })
        .finally(() => {
          setLoading(false);
        });
  }, [orderID, loading]);

  const checkPresent = (array, value, type) => {
    const present = array.find(
      (item) =>
        item.value.toUpperCase() === value.toUpperCase() && item.type === type
    );
    return present;
  };

  const addConfirmation = () => {
    setConfirmations((previous) => {
      const present = checkPresent(
        previous,
        confirmationValue,
        confirmationType
      );

      if (present) {
        toast.error("Confirmation already exists");
        return [...previous];
      }
      return [
        ...previous,
        {
          id: Date.now(),
          value: confirmationValue.toUpperCase(),
          type: confirmationType,
        },
      ];
    });
    setConfirmationType("");
    setConfirmationValue("");
  };

  const removeConfirmation = (id) => {
    setConfirmations((previous) => {
      return previous.filter((item) => item.id !== id);
    });
  };

  const checkNewValue = () => {
    setCheckValue([confirmationValue.toUpperCase()]);
  };

  React.useEffect(() => {
    setDisableAdd(
      !confirmationType ||
        !confirmationValue ||
        !confirmationChecked ||
        checkingConfirmation ||
        error
    );
  }, [
    confirmationType,
    confirmationValue,
    confirmationChecked,
    error,
    checkingConfirmation,
  ]);

  React.useEffect(() => {
    if (!["LPO", "MPESA"].includes(confirmationType.toUpperCase())) {
      setConfirmationChecked(true);
      setError(false);
    } else {
      setCheckingConfirmation(false);
      setConfirmationChecked(false);
    }
  }, [confirmationValue, confirmationType]);

  React.useEffect(() => {
    const equalCheck = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      else {
        if (JSON.stringify(array1) === JSON.stringify(array2)) return true;
        else return false;
      }
    };
    if (!equalCheck(confirmations, currentConfirmations)) setChanged(true);
    else setChanged(false);
  }, [confirmations, currentConfirmations]);

  const handleSaveChanges = () => {
    setSaving(true);
    editOverviewConfirmation({
      orderID,
      confirmations,
    })
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          setLoading(true);
          setOpen(false);
          onChangesSaved();
        } else {
          toast.error("Error saving changes");
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const ConfirmationDisplay = ({ confirmation }) => {
    const [hover, setHover] = React.useState(false);

    return (
      <Paper
        variant="outlined"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{ p: 1 }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Chip label={confirmation.type.toUpperCase()} />
          <Typography variant="overline">{confirmation.value}</Typography>
          <Collapse
            in={hover}
            timeout="auto"
            orientation="horizontal"
            unmountOnExit
          >
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={() => removeConfirmation(confirmation.id)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Collapse>
        </Stack>
      </Paper>
    );
  };

  const [emailAmount, setEmailAmount] = React.useState(0);
  const [emailDate, setEmailDate] = React.useState(new Date());

  React.useEffect(() => {
    if (confirmationType === "EMAIL") {
      setConfirmationValue(
        `${NumberFormat(
          Number(emailAmount).toFixed(2)
        )} || ${emailDate.toDateString()}`
      );
    }
  }, [emailAmount, emailDate, confirmationType]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
      <DialogTitle>
        {orderID} Confirmations
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Collapse in={loading} timeout="auto" unmountOnExit>
          <Skeleton variant="rectangular" height={100} />
        </Collapse>

        <Collapse in={!loading} timeout="auto" unmountOnExit>
          {confirmations.length === 0 ? (
            <Divider>
              <Typography variant="overline">No Confirmations</Typography>
            </Divider>
          ) : (
            <Stack direction="row" spacing={2}>
              <TransitionGroup>
                {confirmations.map((confirmation, index) => (
                  <Collapse key={`${new Date()}_${index}`}>
                    <ConfirmationDisplay confirmation={confirmation} />
                  </Collapse>
                ))}
              </TransitionGroup>
            </Stack>
          )}
        </Collapse>

        <Paper variant="outlined" sx={{ mt: 2 }}>
          <CardActionArea onClick={() => setShowAdd(!showAdd)} sx={{ p: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Add Confirmation</Typography>
              <ExpandMore
                sx={{
                  transform: showAdd ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </Stack>
          </CardActionArea>
          <Collapse in={showAdd} timeout="auto" unmountOnExit>
            <form onSubmit={(e) => e.preventDefault()}>
              <Stack direction="column" spacing={2} sx={{ p: 2, mt: 2 }}>
                <FormControl>
                  <InputLabel id="confTypeSelect">Confirmation Type</InputLabel>
                  <Select
                    labelId="confTypeSelect"
                    sx={{ flexGrow: 1 }}
                    value={confirmationType}
                    onChange={(e) => setConfirmationType(e.target.value)}
                    label="Confirmation Type"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="LPO">LPO</MenuItem>
                    <MenuItem value="EMAIL">Email</MenuItem>
                    <MenuItem value="MPESA">Mpesa</MenuItem>
                    <MenuItem value="CARD">Credit/Debit OrderCard</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </Select>
                </FormControl>

                {confirmationType === "EMAIL" ? (
                  <Stack direction="row" spacing={2}>
                    <DateTimePicker value={emailDate} onChange={setEmailDate} />
                    <TextField
                      label="Amount"
                      value={emailAmount}
                      onChange={(e) => setEmailAmount(e.target.value)}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                    />
                  </Stack>
                ) : (
                  <TextField
                    label="value"
                    value={confirmationValue}
                    onChange={(e) => setConfirmationValue(e.target.value)}
                  />
                )}

                {["LPO", "MPESA"].includes(confirmationType.toUpperCase()) && (
                  <CheckRepeatConfirmation
                    confirmationType={confirmationType}
                    confirmations={checkValue}
                    client={clientID}
                    loading={checkingConfirmation}
                    isLoading={setCheckingConfirmation}
                    isError={setError}
                    isSafe={setConfirmationChecked}
                  />
                )}

                <Stack direction="row" spacing={2}>
                  {["LPO", "MPESA"].includes(
                    confirmationType.toUpperCase()
                  ) && (
                    <LoadingButton
                      variant="contained"
                      color="success"
                      onClick={checkNewValue}
                      loading={checkingConfirmation}
                      disabled={!disableAdd}
                      type={disableAdd ? "submit" : "button"}
                    >
                      Check
                    </LoadingButton>
                  )}
                  <LoadingButton
                    variant="contained"
                    disabled={disableAdd}
                    onClick={addConfirmation}
                    type={!disableAdd ? "submit" : "button"}
                  >
                    Add
                  </LoadingButton>
                </Stack>
              </Stack>
            </form>
          </Collapse>
        </Paper>
      </DialogContent>
      {changed && (
        <DialogActions>
          {reloadWarning && (
            <Alert severity="warning">Changes are visible on reload</Alert>
          )}
          <LoadingButton
            variant="contained"
            color="success"
            startIcon={<Save />}
            onClick={handleSaveChanges}
            loading={saving}
          >
            Save changes
          </LoadingButton>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setConfirmations(currentConfirmations)}
            startIcon={<Restore />}
          >
            Reset
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
