import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import React from "react";
import { editChequeDate } from "../../API/Cheques&Pos";
import { toast } from "react-toastify";
import { CheckCircleOutline, HighlightOffOutlined } from "@mui/icons-material";

export default function BatchEditCheques({ onClose }) {
  const [bank, setBank] = React.useState("I&M");
  const [currency, setCurrency] = React.useState("KES");
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [raw, setRaw] = React.useState("");

  const [finalList, setFinalList] = React.useState([]);
  const [submiting, setSubmiting] = React.useState(false);

  React.useEffect(() => {
    if (raw) {
      const list = raw.split("\n").map((line) => {
        const [date, chequeNumber] = line.split("\t");
        const formatedDate = new Date(
          Date.UTC(year, date.split("/")[1] - 1, date.split("/")[0])
        );

        return {
          date: formatedDate,
          chequeNumber,
          submitted: null,
          error: "",
        };
      });
      setFinalList(list);
    }
  }, [raw, year]);

  const handleSubmit = () => {
    setSubmiting(true);
    let detailList = finalList;
    let counter = 0;
    finalList.forEach(({ date, chequeNumber }, index) => {
      editChequeDate({ chequeNumber, date, bank, currency })
        .then((res) => {
          if (res.status === "success") {
            detailList[index].submitted = true;
          } else {
            detailList[index].submitted = false;
            detailList[index].error = res.message;
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          counter++;
          if (counter === finalList.length) {
            setFinalList((prev) => [...detailList]);
            setSubmiting(false);
            toast.info("All Cheques Submitted");
          }
        });
    });
  };

  return (
    <>
      <DialogTitle>Batch Edit Cheques</DialogTitle>
      <DialogContent dividers>
        <Stack direction="column" spacing={2} alignItems="flex-start">
          <ToggleButtonGroup
            value={bank}
            onChange={(e, newType) => {
              if (newType !== null) setBank(newType);
            }}
            color="success"
            exclusive
          >
            <ToggleButton value="I&M">I&M</ToggleButton>
            <ToggleButton value="NCBA">NCBA</ToggleButton>
            <ToggleButton value="Paramount">Paramount</ToggleButton>
            <ToggleButton value="Equity">Equity</ToggleButton>
            <ToggleButton value="Sidian">Sidian</ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={currency}
            color="info"
            exclusive
            onChange={(e, newType) => {
              if (newType !== null) setCurrency(newType);
            }}
          >
            <ToggleButton value="KES">Kenya Shillings (KSH)</ToggleButton>
            <ToggleButton value="USD">American Dollars ($)</ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label="Year"
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            fullWidth
          />
          <TextField
            label="Cheque Data"
            multiline
            rows={4}
            value={raw}
            onChange={(e) => setRaw(e.target.value)}
            fullWidth
          />
        </Stack>

        <Collapse in={finalList.length > 0}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="flex-start"
            sx={{ my: 2 }}
          >
            {finalList.map(
              ({ date, chequeNumber, submitted, error }, index) => (
                <Paper
                  key={index}
                  sx={{ p: 1, width: "100%" }}
                  variant="outlined"
                >
                  <Stack
                    direction="row"
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction="row" spacing={5} alignItems={"center"}>
                      <Typography>{chequeNumber}</Typography>
                      <Typography variant="overline">
                        {date.toDateString()}
                      </Typography>

                      <SvgIcon
                        sx={{
                          alignSelf: "flex-end",
                        }}
                      >
                        {submitted !== null &&
                          (submitted ? (
                            <CheckCircleOutline color="success" />
                          ) : (
                            <HighlightOffOutlined color="error" />
                          ))}
                      </SvgIcon>
                      {error && <Typography color="error">{error}</Typography>}
                    </Stack>
                    <Fade in={submiting}>
                      <CircularProgress
                        color="info"
                        size={25}
                        sx={{
                          justifySelf: "flex-end",
                        }}
                      />
                    </Fade>
                  </Stack>
                </Paper>
              )
            )}
          </Stack>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={submiting}
          onClick={handleSubmit}
          variant="contained"
          color="success"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </>
  );
}
