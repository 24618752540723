import {
  Box,
  Button,
  Backdrop,
  CardActionArea,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
  Tooltip,
  Fade,
  Alert,
  alpha,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getSelloutByID, updateSelloutProfit } from "../API/tonerApi";
import {
  Article,
  AutoAwesome,
  Calculate,
  Close,
  DateRange,
  ExpandMore,
  Info,
  Print,
  Save,
  Summarize,
  WarningAmberRounded,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import NumberFormat from "../../../../Components/common/NumberFormater";
import { Doughnut } from "react-chartjs-2";
import { CustomThemeContext } from "../../../../Components/common/CustomThemeProvider";
import { Link as RouterLink } from "react-router-dom";
import Decimal from "decimal.js";

export default function MoreDetails() {
  const [loading, setLoading] = React.useState(true);

  const urlDets = new URLSearchParams(useLocation().search);
  const selloutID = urlDets.get("id");

  const { origin } = useOutletContext();

  let browseHistory = useNavigate();
  const goBack = React.useCallback(() => {
    browseHistory(origin);
  }, [browseHistory, origin]);

  React.useEffect(() => {
    if (!selloutID) goBack();
    if (loading)
      getSelloutByID(selloutID).then((res) => {
        if (res.status === "success") {
          setName(res.data.name);
          setDate(new Date(res.data.date));
          setTransactionType(res.data.transactionType);
          setRate(new Decimal(res.data.rate));
          setRevenue(
            new Decimal(
              res.data.transactionType === "debit"
                ? -res.data.revenue
                : res.data.revenue
            )
          );
          setProfit(new Decimal(res.data.profit));
          setMargin(new Decimal(res.data.margin));
          setItems(res.data.items);
          setCosts(() =>
            res.data.items.map((item) => ({
              itemID: item.partnumber,
              cost: new Decimal(item.cost.reported),
              qty: item.quantity,
            }))
          );

          setLoading(false);
        } else {
          toast.error(
            "Could not retrieve data, contact an administrator of the problem persists"
          );
          goBack();
        }
      });
  }, [loading, selloutID, goBack]);

  const [name, setName] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [transactionType, setTransactionType] = React.useState("debit");
  const [rate, setRate] = React.useState(0);
  const [revenue, setRevenue] = React.useState(0);
  const [profit, setProfit] = React.useState(0);
  const [margin, setMargin] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const [costs, setCosts] = React.useState([]);
  const [baseCostType, setBaseCostType] = React.useState("reported");
  const [defaultValues, setDefaultValues] = React.useState(true);

  const costCalc = React.useMemo(() => {
    return costs.reduce((acc, cost) => {
      return acc + cost.cost * cost.qty;
    }, 0);
  }, [costs]);

  const profitCalc = React.useMemo(() => {
    if (transactionType === "debit") {
      return -revenue - costCalc;
    }
    return revenue - costCalc;
  }, [revenue, costCalc, transactionType]);

  const marginCalc = React.useMemo(() => {
    return (profitCalc / revenue) * 100;
  }, [profitCalc, revenue]);

  React.useEffect(() => {
    if (defaultValues)
      if (profitCalc.toFixed(2) !== profit.toFixed(2)) {
        setCosts((prev) => {
          return prev.map((item) => {
            let current = items.find((i) => i.partnumber === item.itemID);
            if (current) {
              item.cost = new Decimal(current.cost.calculated.finalCost);
            }
            return item;
          });
        });
        setBaseCostType("final");
      }
  }, [profitCalc, profit, items, defaultValues]);

  const useFinalCalcCost = () => {
    setDefaultValues(false);
    setCosts((prev) => {
      return prev.map((item) => {
        let current = items.find((i) => i.partnumber === item.itemID);
        if (current) {
          item.cost = new Decimal(current.cost.calculated.finalCost);
        }
        return item;
      });
    });
    setBaseCostType("final");
  };

  const useReportedCost = () => {
    setDefaultValues(false);
    setCosts((prev) => {
      return prev.map((item) => {
        let current = items.find((i) => i.partnumber === item.itemID);
        if (current) {
          item.cost = new Decimal(current.cost.reported);
        }
        return item;
      });
    });
    setBaseCostType("reported");
  };

  const [chartKey, setChartKey] = React.useState("");

  React.useEffect(() => {
    const randomKey = Math.random().toString(36).substring(7);
    setChartKey(randomKey);
  }, [costCalc, profitCalc]);

  const saveNewProfit = () => {
    const data = {
      profit: profitCalc,
      margin: marginCalc,
      id: selloutID,
    };
    updateSelloutProfit(data).then((res) => {
      if (res.status === "success") {
        toast.success("Profit updated successfully");
        setProfit(profitCalc);
        setMargin(marginCalc);
      } else {
        toast.error(
          "Could not update profit, contact an administrator if the problem persists"
        );
      }
    });
  };

  const [printing, setPrinting] = React.useState(false);
  const [startPrint, setStartPrint] = React.useState(false);

  const handleDetailsPrint = () => {
    setPrinting(true);
  };
  const { setTheme } = React.useContext(CustomThemeContext);

  React.useEffect(() => {
    if (printing) {
      const rootElement = document.getElementById("root");
      rootElement.style.display = "none";
      setTheme("light");
      setStartPrint(true);
    }
  }, [printing, setTheme]);

  React.useEffect(() => {
    if (startPrint) {
      window.print();

      const rootElement = document.getElementById("root");
      rootElement.style.display = "";
      setTheme("dark");

      setStartPrint(false);
      setPrinting(false);
    }
  }, [startPrint, setTheme]);

  if (loading)
    return (
      <Dialog open={true}>
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          }}
        >
          <IconButton
            onClick={goBack}
            sx={{
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <Close />
          </IconButton>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    );

  return (
    <Dialog
      open={true}
      onClose={goBack}
      sx={{
        backdropFilter: "blur(4px)",
      }}
      maxWidth="md"
      fullScreen={printing}
      fullWidth
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: "absolute",
          top: 12,
          right: 6,
        }}
      >
        <Fade
          in={profitCalc.toFixed(2) !== profit.toFixed(2)}
          mountOnEnter
          unmountOnExit
        >
          <Tooltip title="Save new profit">
            <IconButton onClick={saveNewProfit} color="info">
              <Save />
            </IconButton>
          </Tooltip>
        </Fade>

        <Tooltip title={printing ? "" : "Print"}>
          <IconButton onClick={handleDetailsPrint}>
            <Print />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 1 }} />
        <Tooltip title="Close">
          <IconButton onClick={goBack}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogTitle
        sx={{
          mr: 3,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Article sx={{ mr: 2 }} />
          {name}
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: "background.paper",
        }}
        dividers
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ mb: 1 }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <DateRange />
                <Typography variant="subtitle" gutterBottom>
                  {date.toLocaleDateString()}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Chip
                  color={transactionType === "debit" ? "error" : "success"}
                  label={transactionType.toUpperCase()}
                />
                <Chip
                  color={rate === 0 ? "success" : "info"}
                  label={rate === 0 ? "KSH" : `USD Rate: ${rate}`}
                />
              </Stack>
              <Box>
                <Typography variant="overline" sx={{ mb: 0.5 }}>
                  Revenue
                </Typography>
                <Typography sx={{ ml: 2 }}>
                  {NumberFormat(revenue.toFixed(2))}
                </Typography>
                <Typography variant="overline" sx={{ mb: 0.5 }}>
                  Cost
                </Typography>
                <Typography sx={{ ml: 2 }}>
                  {NumberFormat(costCalc.toFixed(2))}
                </Typography>
                <Typography variant="overline" sx={{ mb: 0.5 }}>
                  Profit
                </Typography>
                <Typography
                  sx={{
                    ml: 2,
                    color: (theme) =>
                      theme.palette[profit > 0 ? "success" : "error"].main,
                  }}
                >
                  {NumberFormat(profit.toFixed(2))}
                </Typography>
                <Collapse
                  in={profitCalc.toFixed(2) !== profit.toFixed(2)}
                  timeout={1000}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      <AutoAwesome color={"info"} />
                      <Typography
                        variant="overline"
                        sx={{
                          ml: 0.5,
                          color: (theme) => theme.palette.info.main,
                        }}
                      >
                        NEW
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        ml: 2,
                        color: (theme) =>
                          theme.palette[
                            profit < profitCalc ? "success" : "error"
                          ].main,
                      }}
                    >
                      {NumberFormat(profitCalc.toFixed(2))}
                    </Typography>
                  </Stack>
                </Collapse>
                <Typography variant="overline" sx={{ mb: 0.5 }}>
                  Margin
                </Typography>
                <Typography sx={{ ml: 2 }}>
                  {NumberFormat(margin.toFixed(2))}%
                </Typography>
                <Collapse
                  in={marginCalc.toFixed(2) !== margin.toFixed(2)}
                  timeout={1000}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      <AutoAwesome color={"info"} />
                      <Typography
                        variant="overline"
                        sx={{
                          ml: 0.5,
                          color: (theme) => theme.palette.info.main,
                        }}
                      >
                        NEW
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        ml: 2,
                        color: (theme) =>
                          theme.palette[
                            margin < marginCalc ? "success" : "error"
                          ].main,
                      }}
                    >
                      {NumberFormat(marginCalc.toFixed(2))}%
                    </Typography>
                  </Stack>
                </Collapse>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ maxHeight: "50dvh" }}>
            <Doughnut
              key={chartKey}
              data={{
                labels: ["Costs", "Revenue", "profit"],
                datasets: [
                  {
                    data: [costCalc, revenue, profitCalc],
                    backgroundColor: ["#f44336", "#2196f3", "#4caf50"],
                  },
                ],
              }}
            />
          </Grid>
        </Grid>
        {transactionType === "credit" &&
          !printing &&
          (baseCostType === "reported" ? (
            <Button
              onClick={useFinalCalcCost}
              color="info"
              variant="outlined"
              sx={{ mt: "auto" }}
              startIcon={<Calculate />}
            >
              Use Final Cost
            </Button>
          ) : (
            <Button
              onClick={useReportedCost}
              color="info"
              variant="outlined"
              sx={{ mt: "auto" }}
              startIcon={<Summarize />}
            >
              Use Reported Cost
            </Button>
          ))}
        {printing && (
          <Typography variant="overline" sx={{ color: "text.secondary" }}>
            {baseCostType === "reported"
              ? "Using Reported Costs"
              : "Using Final Cost"}
          </Typography>
        )}

        <Paper variant="outlined" sx={{ mt: 2 }}>
          {items.length > 0 ? (
            <List disablePadding>
              <ListItem
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderBottomColor: "divider",
                  borderBottomStyle: "solid",
                  pb: 1,
                }}
              >
                <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
                  Partnumber
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 auto", maxWidth: "15%" }}
                >
                  Description
                </Typography>
                <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
                  QTY
                </Typography>
                <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
                  Revenue
                </Typography>
                <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
                  Unit
                </Typography>
                <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
                  Profit
                </Typography>
                <Typography variant="body1" sx={{ flex: "0 1 auto" }} />
              </ListItem>
              {items.map((item, index) => (
                <ItemDisplay
                  item={item}
                  key={index}
                  setMainCosts={setCosts}
                  mainCostType={baseCostType}
                />
              ))}
            </List>
          ) : (
            <>
              <Divider />
              <Typography variant="overline">
                No products found in this transaction
              </Typography>
              <Divider />
            </>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

function ItemDisplay({ item, setMainCosts, mainCostType }) {
  const [copying, setCopying] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (copying) {
      setTimeout(() => {
        setCopying(false);
      }, 3000);
    }
  }, [copying]);

  const profit = React.useMemo(() => {
    let temp = 0;
    if (mainCostType === "final")
      temp = item.revenue - item.cost.calculated.finalCost * item.quantity;
    else temp = item.revenue - item.cost.reported * item.quantity;

    if (temp.toFixed(2) === 0.0) return 0;
    return temp;
  }, [item, mainCostType]);

  const profitColor = React.useMemo(() => {
    if (profit < 0) return "error";
    return "default";
  }, [profit]);

  return (
    <ListItem
      sx={{
        borderBottom: 1,
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
      disablePadding
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          p: 0.5,
          pl: 2,
          ...(item.cost.calculated.warning && {
            bgcolor: (theme) => alpha(theme.palette.warning.main, 0.2),
          }),
        }}
        onClick={() => setOpen(!open)}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
            {item.partnumber}
          </Typography>
          {item.cost.calculated.warning && (
            <WarningAmberRounded color="warning" />
          )}
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip title={" View details"}>
            <IconButton
              component={RouterLink}
              to={`/toners/viewToner?id=${item.tonerID}`}
              target="_blank"
              color="info"
              size="small"
            >
              <Info size="small" />
            </IconButton>
          </Tooltip>
          <Typography variant="body2">
            {item.description.toUpperCase().slice(0, 20)}...
          </Typography>
        </Stack>
        <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
          {item.quantity}
        </Typography>
        <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
          {NumberFormat(item.revenue.toFixed(2))}
        </Typography>
        <Typography variant="body1" sx={{ flex: "0 1 auto" }}>
          {NumberFormat(item.unit.toFixed(2))}
        </Typography>
        <Typography
          variant="body1"
          sx={{ flex: "0 1 auto" }}
          color={profitColor}
        >
          {NumberFormat(profit.toFixed(2))}
        </Typography>
        <ExpandMore
          sx={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      </CardActionArea>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ItemPoriftCalculation
          revenue={item.revenue / item.quantity}
          qty={item.quantity}
          costs={item.cost}
          partnumber={item.partnumber}
          setMainCosts={setMainCosts}
          mainCostType={mainCostType}
          sold={item.sold}
        />
      </Collapse>
    </ListItem>
  );
}

function ItemPoriftCalculation({
  revenue,
  qty,
  costs,
  partnumber,
  setMainCosts,
  mainCostType,
  sold,
}) {
  const reported = costs?.reported || 0;
  const actualCosts = React.useMemo(
    () => costs.actual.reverse() || [],
    [costs]
  );

  const costBreakdown = costs.calculated.breakdown || [];

  const finalCost = costs.calculated.finalCost || 0;

  const costAprox = (cost) => {
    let incriment = 50;
    let min = reported - incriment;
    let max = reported + incriment;
    return min <= cost && cost <= max;
  };

  const [costToUse, setCostToUse] = React.useState(
    mainCostType === "final" ? finalCost : reported
  );
  const [useName, setUseName] = React.useState(mainCostType);

  const handleCostChange = (costName, cost) => {
    setCostToUse(cost);
    setUseName(costName);
    setMainCosts((prev) => {
      let current = prev.find((item) => item.itemID === partnumber);
      if (current) {
        current.cost = cost;
      }
      return [...prev];
    });
  };

  const SelectableCost = ({ name, cost, children }) => {
    const selected = useName === name;
    return (
      <CardActionArea
        onClick={() => handleCostChange(name, cost)}
        sx={{
          px: 2,
          cursor: "pointer",
          borderRadius: 3,
          maxWidth: "fit-content",
          ...(selected && {
            border: (theme) => `1px solid ${theme.palette.info.main}`,
            bgcolor: (theme) => theme.palette.action.hover,
          }),
        }}
      >
        {children}
      </CardActionArea>
    );
  };

  return (
    <Paper sx={{ p: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectableCost name="reported" cost={reported}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="overline">Reported</Typography>
              <Typography variant="body1">
                {NumberFormat(reported.toFixed(2))}
              </Typography>
            </Stack>
          </SelectableCost>

          <SelectableCost name="final" cost={finalCost}>
            <Tooltip
              componentsProps={{
                tooltip: { sx: { maxWidth: "fit-content" } },
              }}
              title={
                <>
                  <Typography>Cost breakdown (Average)</Typography>
                  {costs.calculated.warning && (
                    <Alert severity="warning">Items Over Limit</Alert>
                  )}
                  <Typography variant="overline">
                    Previous Sales: {sold}
                  </Typography>
                  {costBreakdown.map((cost, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      <Typography variant="overline">{cost.name}</Typography>
                      <Typography variant="body1">
                        {NumberFormat(cost.cost.toFixed(2))}
                      </Typography>
                      <Typography variant="overline">
                        QTY: {cost.quantity}
                      </Typography>
                      <Typography variant="overline">
                        Order QTY: {cost.original_qty}
                      </Typography>
                      <Typography variant="overline">
                        Remaining QTY: {cost.remaining}
                      </Typography>
                    </Stack>
                  ))}
                </>
              }
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="overline">Final</Typography>
                <Typography variant="body1">
                  {NumberFormat(finalCost.toFixed(2))}
                </Typography>

                {costs.calculated.warning && (
                  <WarningAmberRounded color="warning" />
                )}
              </Stack>
            </Tooltip>
          </SelectableCost>

          {actualCosts.length > 0 && (
            <Box sx={{ ml: 2 }}>
              <Typography variant="overline" gutterBottom>
                Actual
              </Typography>
              <Box sx={{ ml: 2 }}>
                {actualCosts.map((cost, index) => (
                  <SelectableCost key={index} name={cost.name} cost={cost.cost}>
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      <Typography variant="overline">{cost.name}</Typography>
                      <Tooltip
                        title={
                          cost.cost === reported
                            ? "Reported cost is the same"
                            : costAprox(cost.cost)
                            ? "Reported cost is close"
                            : ""
                        }
                      >
                        <Typography
                          variant="body1"
                          color={(theme) => {
                            if (cost.cost === reported)
                              return theme.palette.success.main;
                            if (costAprox(cost.cost))
                              return theme.palette.info.main;
                            return theme.palette.text.primary;
                          }}
                        >
                          {cost.cost !== reported &&
                            costAprox(cost.cost) &&
                            "~ "}
                          {NumberFormat(cost.cost.toFixed(2))}{" "}
                          {cost.cost === reported && (
                            <Chip label="Same" color="success" />
                          )}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </SelectableCost>
                ))}
              </Box>
            </Box>
          )}
          <SelectableCost name="actualAverage" cost={costs?.actualAverage}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="overline">Actual Average</Typography>
              <Typography variant="body1">
                {NumberFormat(costs?.actualAverage.toFixed(2))}
              </Typography>
            </Stack>
          </SelectableCost>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">Revenue</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            {NumberFormat(revenue.toFixed(2))}
          </Typography>
          <Typography variant="overline">Profit</Typography>
          <Typography
            variant="body1"
            sx={{
              ml: 2,
              color: (theme) =>
                theme.palette[revenue - costToUse > 0 ? "success" : "error"]
                  .main,
            }}
          >
            {NumberFormat((revenue - costToUse).toFixed(2))}
          </Typography>
          <Typography variant="overline">Total Profit</Typography>
          <Typography
            variant="body1"
            sx={{
              ml: 2,
              color: (theme) =>
                theme.palette[revenue - costToUse > 0 ? "success" : "error"]
                  .main,
            }}
          >
            {NumberFormat(((revenue - costToUse) * qty).toFixed(2))}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
