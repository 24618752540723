import React from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { getAdminOrderOverview } from "../../API/Cheques&Pos";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Slide,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Cancel,
  CancelOutlined,
  Check,
  ExpandMore,
  Info,
  ManageAccounts,
  PictureAsPdf,
  Summarize,
} from "@mui/icons-material";
import NumberFormat from "../../../Components/common/NumberFormater";
import { getExchangeRate } from "../../API/Sales";
import { getQuotePDF } from "../../API/SalesAdmin";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import AddConfirmation from "../common/AddConfirmation";
import config from "../../Config";

export default function OrderOverview() {
  const urlParameters = new URLSearchParams(useLocation().search);
  const orderID = urlParameters.get("i").trim();

  const [orders, setOrders] = React.useState([]);
  const [showOrderPicker, setShowOrderPicker] = React.useState(false);
  const [orderNumber, setOrderNumber] = React.useState(0);
  const [currentOrder, setCurrentOrder] = React.useState(undefined);

  const [loading, setLoading] = React.useState(true);
  const [orderData, setOrderData] = React.useState({});

  let browseHistory = useNavigate();
  const { origin } = useOutletContext();

  const goBack = () => {
    browseHistory(origin);
  };

  React.useEffect(() => {
    let list = orderID.split("/");
    setOrders(list);
    setShowOrderPicker(list.length > 1);
    setCurrentOrder(list[0]);
    setOrderNumber(0);
  }, [orderID]);

  React.useEffect(() => {
    if (loading && currentOrder && !currentOrder.startsWith("MANUALOR")) {
      // Fetch order data
      getAdminOrderOverview(currentOrder).then((data) => {
        setOrderData(data);
        setLoading(false);
      });
    }
  }, [loading, currentOrder]);

  const [manualOrder, setManualOrder] = React.useState(false);

  React.useEffect(() => {
    if (currentOrder && currentOrder.startsWith("MANUALOR"))
      setManualOrder(true);
    else setManualOrder(false);
  }, [currentOrder]);

  const [exchangeRates, setExchangeRates] = React.useState({
    USD: { rate: 1 },
    KES: { rate: 1 },
  });

  React.useEffect(() => {
    getExchangeRate().then((res) => {
      setExchangeRates({ USD: res[0], KES: res[2] });
      setRate(res[2].rate / res[0].rate);
    });
  }, []);

  const [rate, setRate] = React.useState(
    exchangeRates.KES.rate / exchangeRates.USD.rate
  );

  const [profitTotals, setProfitTotals] = React.useState({});
  const profitTotal = React.useMemo(() => {
    let total = 0;
    Object.keys(profitTotals).forEach((item) => {
      total += profitTotals[item];
    });
    return total;
  }, [profitTotals]);

  const handlePickerMove = (direction) => {
    let num = orderNumber;
    if (direction === "next") num = num + 1;
    else num = num - 1;
    setCurrentOrder(orders[num]);
    setOrderNumber(num);
    setOrderData({});
    setProfitTotals({});
    setLoading(true);
  };

  return (
    <Dialog open={true} fullScreen>
      <DialogTitle>Order Overview</DialogTitle>
      <DialogContent divider="paper">
        <Slide in={showOrderPicker} direction="right">
          <Paper
            variant="outlined"
            sx={{
              position: "absolute",
              top: 0,
              right: 24,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 0,
            }}
            elevation={0}
          >
            <Collapse
              in={orderNumber > 0}
              timeout="auto"
              unmountOnExit
              orientation="horizontal"
            >
              <CardActionArea
                sx={{ py: 1.5 }}
                onClick={() => handlePickerMove("back")}
                disabled={orderNumber <= 0}
              >
                <ArrowBack />
              </CardActionArea>
            </Collapse>
            <Typography
              variant="overline"
              sx={{
                px: 1,
                py: 1.5,
                bgcolor: (theme) => theme.palette.background.default,
              }}
            >
              {currentOrder}
            </Typography>
            <Collapse
              in={orderNumber < orders.length - 1}
              timeout="auto"
              orientation="horizontal"
              unmountOnExit
            >
              <CardActionArea
                sx={{ py: 1.5 }}
                onClick={() => handlePickerMove("next")}
                disabled={orderNumber >= orders.length - 1}
              >
                <ArrowForward />
              </CardActionArea>
            </Collapse>
          </Paper>
        </Slide>
        <Collapse in={manualOrder} timeout="auto" unmountOnExit>
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>Manual Order</AlertTitle>
            This order was created manually, there is no data to display
          </Alert>
        </Collapse>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <CollapseArea title="Order Details">
              <Stack
                direction={{ xd: "column", md: "row" }}
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack spacing={1} sx={{ p: 2 }}>
                  <Typography>{orderData.orderID}</Typography>
                  <Typography variant="overline">
                    {orderData.date_created}
                  </Typography>
                  <Typography>{orderData.salesPerson}</Typography>
                  <Typography>{orderData.clientName}</Typography>
                  <Typography>{orderData.total}</Typography>
                  <Collapse in={orderData.exempt}>
                    <Alert severity="error">
                      <AlertTitle>Exempt from VAT</AlertTitle>
                    </Alert>
                  </Collapse>
                </Stack>
                <Stack spacing={1} sx={{ p: 2 }}>
                  <Typography variant="title">Confirmations</Typography>
                  <Stack
                    spacing={1}
                    sx={{ p: 1 }}
                    divider={<Divider flexItem />}
                  >
                    {orderData.confirmations.map((confirmation, index) => (
                      <Box key={`${new Date()}_${index}`}>
                        <Typography>{confirmation.type}</Typography>
                        <Typography variant="overline">
                          {confirmation.value}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                  <AddConfirmation
                    orderID={currentOrder}
                    onChangesSaved={() => setLoading(true)}
                  />
                </Stack>
              </Stack>
              <Divider />

              <Stack spacing={1} sx={{ p: 2 }}>
                <HandleExchangeRate rate={rate} setRate={setRate} />
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>Total buying price </Typography>
                  <Typography>
                    {orderData.currency} {NumberFormat(profitTotal.toFixed(2))}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>Selling Price:</Typography>
                  <Typography>
                    {orderData.currency}{" "}
                    {NumberFormat(orderData.amount.toFixed(2))}
                  </Typography>
                </Stack>
                <Divider />
                <Divider />
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>Profit</Typography>
                  <Stack direction={"row"} spacing={3}>
                    <Typography
                      sx={{
                        color: (theme) =>
                          orderData.amount - profitTotal > 0
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    >
                      {NumberFormat(
                        (
                          ((orderData.amount - profitTotal) /
                            orderData.amount) *
                          100
                        ).toFixed(2)
                      )}
                      %
                    </Typography>
                    <Typography
                      sx={{
                        color: (theme) =>
                          orderData.amount - profitTotal > 0
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    >
                      {orderData.currency}{" "}
                      {NumberFormat(
                        (orderData.amount - profitTotal).toFixed(2)
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <Collapse in={orderData.exempt} mountOnEnter>
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Typography>VAT Exempt</Typography>
                      <Typography>
                        {orderData.currency}{" "}
                        {NumberFormat(
                          (profitTotal * config.VAT - profitTotal).toFixed(2)
                        )}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Divider />
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Typography>Final Profit</Typography>
                      <Stack direction={"row"} spacing={3}>
                        <Typography
                          sx={{
                            color: (theme) =>
                              orderData.amount - profitTotal * config.VAT > 0
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                          }}
                        >
                          {NumberFormat(
                            (
                              ((orderData.amount - profitTotal * config.VAT) /
                                profitTotal) *
                              100
                            ).toFixed(2)
                          )}
                          %
                        </Typography>
                        <Typography
                          sx={{
                            color: (theme) =>
                              orderData.amount - profitTotal * config.VAT > 0
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                          }}
                        >
                          {orderData.currency}{" "}
                          {NumberFormat(
                            (
                              orderData.amount -
                              profitTotal * config.VAT
                            ).toFixed(2)
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Collapse>
              </Stack>
            </CollapseArea>
            <CollapseArea title="Status" closed>
              <Button
                component={Link}
                to={`/adminOrderManager/manage_status?i=${currentOrder}`}
                variant="outlined"
                startIcon={<ManageAccounts />}
                sx={{ ml: 2, mt: 2 }}
              >
                Manage Status
              </Button>
              <Stack
                spacing={1}
                direction="column-reverse"
                dividers={<Divider />}
                sx={{ p: 2 }}
              >
                {orderData?.history.reverse().map((status, index) => (
                  <Card sx={{ pb: 2 }} key={index}>
                    <CardContent>
                      <Typography variant="title" component="div">
                        {status.status.toUpperCase()}
                      </Typography>
                      <Typography variant="overline">{status.date}</Typography>
                      <Typography component="div">{status.note}</Typography>
                      <Typography sx={{ float: "right" }}>
                        {status.user}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            </CollapseArea>
            <CollapseArea title="Items">
              {orderData.items.map((item, index) => (
                <ItemDisplay
                  key={`${new Date()}_${index}`}
                  order={orderData}
                  item={item}
                  rate={rate}
                  setRate={setRate}
                  setProfitTotals={setProfitTotals}
                  exempt={orderData.exempt}
                />
              ))}
            </CollapseArea>
            {orderData?.quotes?.length > 0 && (
              <CollapseArea title="Quote Details" closed>
                <QuoteDetails quotes={orderData?.quotes} />
              </CollapseArea>
            )}
          </>
        )}
      </DialogContent>
      <Paper elevation={0}>
        <DialogActions>
          <Button
            variant="outlined"
            color="warning"
            onClick={goBack}
            startIcon={<Cancel />}
          >
            Close
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}

function ItemDisplay({ order, item, rate, setRate, setProfitTotals, exempt }) {
  const poList = React.useMemo(() => {
    let list = [];

    item.poitems.forEach((poitem) => {
      if (!list.some((i) => i.poNumber === poitem.poNumber)) {
        list.push({ poNumber: poitem.poNumber, items: [] });
      }
    });
    return list;
  }, [item]);

  const qtyOrdered = React.useMemo(() => {
    let startQty = 0;
    item.poitems.forEach((poitem) => {
      let currentPO = poList.find((i) => i.poNumber === poitem.poNumber);
      currentPO.items.push(poitem);

      if (poitem.pendingID === null) startQty += poitem.quantity;
    });
    return startQty;
  }, [item, poList]);

  const [poTotals, setPoTotals] = React.useState({});
  const poTotal = React.useMemo(() => {
    let total = 0;
    Object.keys(poTotals).forEach((po) => {
      total += poTotals[po];
    });
    return total;
  }, [poTotals]);

  React.useEffect(() => {
    setProfitTotals((prev) => {
      let newObject = { ...prev };
      newObject[item.id] = poTotal;
      return { ...newObject };
    });
  }, [item, poTotal, setProfitTotals]);

  const invoiceTotalQty = React.useMemo(() => {
    let total = 0;
    item.invoice.forEach((invoice) => {
      total += invoice.quantity;
    });
    return total;
  }, [item]);

  return (
    <Paper variant="outlined" sx={{ p: 2, borderRadius: 0 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderRight: (theme) => `2px solid ${theme.palette.divider}`,
            mt: 1,
          }}
        >
          <Stack direction="column" spacing={1} alignItems="flex-start">
            <Typography variant="h6">{item.partNumber}</Typography>
            <Typography variant="overline">{item.description}</Typography>
            <Typography variant="overline">
              Quantity: {item.quantity}{" "}
            </Typography>
            <Chip
              label={`Ordered: ${qtyOrdered}`}
              color={item.quantity === qtyOrdered ? "success" : "error"}
              variant="outlined"
            />
            {item.quantity !== qtyOrdered && (
              <Alert severity="error">
                <AlertTitle>Quantity Mismatch</AlertTitle>
                {item.quantity > qtyOrdered
                  ? "Less items ordered"
                  : "More items ordered than requested by client"}
              </Alert>
            )}
            <Typography variant="body1">
              Unit Price {order.currency} {NumberFormat(item.price.toFixed(2))}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack direction="column" spacing={2} sx={{ pr: 2 }}>
            <HandleExchangeRate rate={rate} setRate={setRate} small />

            {poList.map((poitem, index) => (
              <PoTotals
                key={`${new Date()}_${index}`}
                poitem={poitem}
                itemCurrency={order.currency}
                rate={rate}
                setPoTotals={setPoTotals}
              />
            ))}
            <Divider />
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography>Selling Price:</Typography>
              <Typography>
                {order.currency}{" "}
                {NumberFormat((item.price * item.quantity).toFixed(2))}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography>Total PO:</Typography>
              <Typography>
                {order.currency} {NumberFormat(poTotal.toFixed(2))}
              </Typography>
            </Stack>
            <Divider />
            <Divider />
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography>Profit:</Typography>
              <Typography
                sx={{
                  color: (theme) =>
                    item.price * item.quantity - poTotal > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                }}
              >
                {order.currency}{" "}
                {NumberFormat(
                  (item.price * item.quantity - poTotal).toFixed(2)
                )}
              </Typography>
            </Stack>
            {exempt && (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>VAT EXEMPT</Typography>
                  <Typography>
                    {order.currency}{" "}
                    {NumberFormat((poTotal * config.VAT - poTotal).toFixed(2))}
                  </Typography>
                </Stack>
                <Divider />
                <Divider />
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>Final Profit</Typography>
                  <Typography
                    sx={{
                      color: (theme) =>
                        item.price * item.quantity - poTotal * config.VAT > 0
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    }}
                  >
                    {order.currency}{" "}
                    {NumberFormat(
                      (
                        item.price * item.quantity -
                        poTotal * config.VAT
                      ).toFixed(2)
                    )}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <>
            <Divider />
            <Typography variant="overline" gutterBottom>
              Invoices
            </Typography>
            <Stack direction="column" spacing={1}>
              {item?.invoice.map((invoice, index) => (
                <Stack
                  key={`${new Date()}_${index}`}
                  direction="row"
                  spacing={1}
                  justifyContent={"space-between"}
                >
                  <Chip
                    icon={<Summarize />}
                    color="info"
                    label={invoice.internalID}
                    component={Link}
                    to={`/invoice/summary?invoiceID=${invoice.invoiceID}`}
                    target="_blank"
                    sx={{ cursor: "pointer" }}
                  />
                  <Chip
                    icon={<Summarize />}
                    color="info"
                    variant="outlined"
                    label={`QTY: ${invoice.quantity}`}
                  />
                </Stack>
              ))}
            </Stack>
            <Chip
              color={invoiceTotalQty === item.quantity ? "success" : "error"}
              label={`Invoiced: ${invoiceTotalQty} / ${item.quantity}`}
              sx={{ float: "right", mr: 1, mt: 1 }}
            />
          </>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={1}>
            {poList.map((poitem, index) => (
              <POSItemDisplay
                key={`${new Date()}_${index}`}
                poitem={poitem}
                itemUnitPrice={item.price}
                itemCurrency={order.currency}
                rate={rate}
                exempt={exempt}
              />
            ))}
          </Stack>

          <Stack direction="column" spacing={1} divider={<Divider flexItem />}>
            {item.pending.map((pending, index) => (
              <PendingItemDisplay
                key={`${new Date()}_${index}`}
                pending={pending}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

function PoTotals({ poitem, itemCurrency, rate, setPoTotals }) {
  const total = React.useMemo(() => {
    let total = 0;
    poitem.items.forEach((item) => {
      total += item.price * item.quantity;
    });
    return total;
  }, [poitem]);

  const [needsConvertion, setNeedsConvertion] = React.useState(false);
  const [convertedTotal, setConvertedTotal] = React.useState(0);
  const [usdToKes, setUsdToKes] = React.useState(false);

  React.useEffect(() => {
    if (itemCurrency !== poitem.items[0].currency) {
      setNeedsConvertion(true);

      if (itemCurrency === "USD") {
        setConvertedTotal(total / rate);
        setUsdToKes(false);
      } else {
        setConvertedTotal(total * rate);
        setUsdToKes(true);
      }
    } else {
      setNeedsConvertion(false);
    }
  }, [total, itemCurrency, rate, poitem]);

  React.useEffect(() => {
    const changePoTotal = (poNumber, newTotal) => {
      setPoTotals((prev) => {
        let newObject = { ...prev };
        newObject[poNumber] = newTotal;
        return { ...newObject };
      });
    };
    if (needsConvertion) {
      changePoTotal(poitem.poNumber, convertedTotal);
    } else {
      changePoTotal(poitem.poNumber, total);
    }
  }, [needsConvertion, convertedTotal, total, poitem, setPoTotals]);

  return (
    <>
      <Typography>{poitem.poNumber}</Typography>
      {poitem.items.map((item, index) => (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          key={`${new Date()}_${index}`}
        >
          <Box>
            <Typography>{item.partnumber}</Typography>
            <Typography variant="overline">Qty {item.quantity}</Typography>
          </Box>
          <Typography>
            {item.currency}{" "}
            {NumberFormat((item.price * item.quantity).toFixed(2))}
          </Typography>
        </Stack>
      ))}
      <Divider />

      {needsConvertion && (
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography>Conversion</Typography>
          <Typography>
            {NumberFormat(total.toFixed(2))}
            {usdToKes ? " X " : " / "}
            {NumberFormat(rate.toFixed(2))} ={" "}
            {NumberFormat(convertedTotal.toFixed(2))}
          </Typography>
        </Stack>
      )}

      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography>PO {poitem.poNumber} Total</Typography>
        <Typography>
          {itemCurrency}{" "}
          {needsConvertion
            ? NumberFormat(convertedTotal.toFixed(2))
            : NumberFormat(total.toFixed(2))}
        </Typography>
      </Stack>
    </>
  );
}

function POSItemDisplay({ poitem, itemUnitPrice, itemCurrency, rate, exempt }) {
  const total = React.useMemo(() => {
    let total = 0;
    poitem.items.forEach((item) => {
      total += item.price;
    });
    return total;
  }, [poitem]);

  const [isProfit, setIsProfit] = React.useState(true);
  const [profitAmount, setProfitAmount] = React.useState(0);

  React.useEffect(() => {
    let poTotal = total;
    if (itemCurrency !== poitem.items[0].currency) {
      if (itemCurrency === "USD") {
        poTotal = total / rate;
      } else {
        poTotal = total * rate;
      }
    }

    if (poTotal < itemUnitPrice) setIsProfit(true);
    else setIsProfit(false);

    setProfitAmount(itemUnitPrice - poTotal);
  }, [total, itemCurrency, itemUnitPrice, rate, poitem]);

  return (
    <Box>
      <CardActionArea
        component={Link}
        to={`/Pos/edit?pos=${poitem.poNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="title">PO: {poitem.poNumber}</Typography>
      </CardActionArea>
      {poitem.items.map((item, index) => (
        <Paper
          key={`${new Date()}_${index}`}
          variant="outlined"
          sx={{
            p: 1,
            ml: item.pendingID !== null && 2,
            borderLeft: (theme) =>
              item.pendingID !== null && `4px solid ${theme.palette.info.main}`,
          }}
        >
          <Stack direction="column" spacing={0} alignItems={"flex-start"}>
            <Typography>{item.partnumber} </Typography>
            {item.storeID && <Chip label="Store" color="info" />}
            <Typography variant="overline">{item.description}</Typography>
            <Typography variant="overline">
              Quantity: {item.quantity}
            </Typography>
            <Typography variant="overline">
              {item.currency} {NumberFormat(item.price.toFixed(2))}
            </Typography>
          </Stack>
        </Paper>
      ))}
      <Typography variant="overline">
        Total: {poitem.items[0].currency} {NumberFormat(total.toFixed(2))}
      </Typography>
      <Divider sx={{ mb: 0.5 }} />
      <Divider />
      <Typography
        variant="overline"
        sx={{
          color: (theme) =>
            isProfit ? theme.palette.success.main : theme.palette.error.main,
        }}
      >
        Profit: {itemCurrency} {NumberFormat(profitAmount.toFixed(2))}
      </Typography>
      {exempt && (
        <>
          <Divider />
          <Typography variant="overline">
            VAT EXEMPT: {poitem.items[0].currency}{" "}
            {NumberFormat((total * config.VAT - total).toFixed(2))}
          </Typography>
          <Divider sx={{ mb: 0.5 }} />
          <Divider />
          <Typography
            variant="overline"
            sx={{
              color: (theme) =>
                profitAmount - (total * config.VAT - total) > 0
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            Final Profit: {itemCurrency}{" "}
            {NumberFormat(
              (profitAmount - (total * config.VAT - total)).toFixed(2)
            )}
          </Typography>
        </>
      )}
    </Box>
  );
}

function PendingItemDisplay({ pending }) {
  return (
    <Alert severity="warning">
      <AlertTitle>Pending</AlertTitle>
      <Typography>{pending.description}</Typography>
      <Typography variant="overline">Quantity: {pending.quantity}</Typography>
    </Alert>
  );
}

function HandleExchangeRate({ rate, setRate, small = false }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(rate);

  const handleSave = (e) => {
    e.preventDefault();
    setRate(+value);
    setOpen(false);
  };

  return (
    <>
      <Collapse in={!open} timeout="auto" unmountOnExit>
        {small ? (
          <CardActionArea onClick={() => setOpen(!open)}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="overline">
                RATE @ {NumberFormat(rate.toFixed(2))}
              </Typography>
              <Info
                sx={{
                  fontSize: 16,
                }}
              />
            </Stack>
          </CardActionArea>
        ) : (
          <Fab onClick={() => setOpen(!open)} variant="extended" size="small">
            RATE @ {NumberFormat(rate.toFixed(2))}
          </Fab>
        )}
      </Collapse>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <form onSubmit={(e) => e.preventDefault(e)}>
          <TextField
            label="Exchange Rate"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="number"
            variant="outlined"
            onWheel={(e) => e.target.blur()}
            onClick={(e) => e.target.select()}
            InputProps={{
              endAdornment: (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Collapse in={value !== rate} timeout="auto" unmountOnExit>
                    <Tooltip title="Save">
                      <IconButton
                        color="success"
                        onClick={(e) => handleSave(e)}
                        type="submit"
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>
                  </Collapse>
                  <Tooltip title="Cancel">
                    <IconButton onClick={() => setOpen(!open)} color="warning">
                      <CancelOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ),
            }}
          />
        </form>
      </Collapse>
    </>
  );
}

function QuoteDetails({ quotes }) {
  const [downloading, setDownloading] = React.useState(false);

  const getPDF = (quoteID) => {
    setDownloading(true);
    getQuotePDF(quoteID, "Order Overview")
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const QuoteDisplay = ({ quote }) => (
    <Paper variant="outlined" sx={{ borderRadius: 0 }}>
      <Stack direction="column" spacing={1} sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6">{quote.id}</Typography>
          <LoadingButton
            onClick={() => getPDF(quote.id)}
            loading={downloading}
            startIcon={<PictureAsPdf />}
          >
            Download PDF
          </LoadingButton>
        </Stack>
        <Typography variant="overline">{quote.date}</Typography>
        <Typography variant="overline">Validity: {quote.validity}</Typography>
        <Typography variant="overline">Delivery: {quote.delivery}</Typography>
        {quote?.clientRef && (
          <Typography variant="overline">
            Client Ref: {quote.clientRef}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
  return (
    <>
      {quotes.map((quote, index) => (
        <QuoteDisplay key={`${new Date()}_${index}`} quote={quote} />
      ))}
    </>
  );
}

function CollapseArea({ title, children, closed = false }) {
  const [open, setOpen] = React.useState(closed ? false : true);

  return (
    <Paper variant="outlined" sx={{ mb: 1 }}>
      <Tooltip title={open ? "Collapse" : "Expand"}>
        <Paper
          elevation={1}
          sx={{
            borderRadius: 0,
          }}
        >
          <CardActionArea onClick={() => setOpen(!open)}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1 }}
            >
              <Typography variant="h5" color="primary">
                {title}
              </Typography>
              <SvgIcon
                sx={{
                  mr: 2,
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
              >
                <ExpandMore />
              </SvgIcon>
            </Stack>
          </CardActionArea>
        </Paper>
      </Tooltip>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Paper>
  );
}

function LoadingScreen() {
  return (
    <Stack direction="column" spacing={2}>
      <Skeleton variant="rectangular" height={200} />
      <Skeleton variant="rectangular" height={200} />
    </Stack>
  );
}
