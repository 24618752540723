import React from "react";

import {
  Zoom,
  Fab,
  Dialog,
  useMediaQuery,
  Tooltip,
  IconButton,
  Collapse,
  Typography,
  SvgIcon,
} from "@mui/material";
import { useTheme } from "@emotion/react";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import AreYouSure from "./AreYouSure";

export default function FloatingFormButton({
  title,
  children,
  open,
  setOpen,
  DialogFullScreen,
  disableConfirm,
  forceMobile,
  icon = <AddIcon />,
  isFloating = true,
  group = false,
}) {
  const [isMobile, setIsMobile] = React.useState(false),
    [sure, setSure] = React.useState(false);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [innitZoom, setInnitZoom] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setInnitZoom(false);
    }, 225);
  }, []);

  React.useEffect(() => {
    if (forceMobile) {
      setIsMobile(true);
      return;
    }
    setIsMobile(isMobileView);
  }, [forceMobile, isMobileView]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (disableConfirm) {
      setOpen(false);
      return;
    }
    setSure(true);
  };

  const confirmClose = () => {
    setSure(false);
    setOpen(false);
  };

  const mobileStyle = {
    position: "fixed",
    bottom: 16,
    right: 16,
  };

  const MobileToolTip = (props) => {
    if (isMobile)
      return (
        <Zoom in={true} appear={innitZoom}>
          <Tooltip title={title}>{props.children}</Tooltip>
        </Zoom>
      );
    return (
      <Zoom in={true} appear={innitZoom}>
        {props.children}
      </Zoom>
    );
  };

  const closeSure = () => {
    setSure(false);
  };

  const FloatingButton = () => {
    const [hover, setHover] = React.useState(false);

    const time1 = 200,
      time2 = 400,
      time3 = 200;

    return (
      <Box
        sx={{
          ...(!group && {
            position: "fixed",
            bottom: 8,
            right: 8,
            zIndex: 1000,
          }),
        }}
      >
        <Fab
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setOpen(true)}
          variant={hover ? "extended" : "circular"}
          color="primary"
        >
          <SvgIcon sx={{ mr: hover ? 2 : 0 }}>{icon}</SvgIcon>
          <Collapse
            in={hover}
            orientation="horizontal"
            timeout={{
              appear: time1,
              enter: time2,
              exit: time3,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "nowrap",
                pr: "10px",
              }}
            >
              {title}
            </Typography>
          </Collapse>
        </Fab>
      </Box>
    );
  };

  return (
    <React.Fragment>
      {isMobile || !isFloating ? (
        <MobileToolTip>
          <Fab
            onClick={handleClickOpen}
            color="primary"
            variant={isMobile ? "circular" : "extended"}
            sx={{
              mb: 2,
              ...(isMobile && mobileStyle),
            }}
          >
            <SvgIcon sx={!isMobile && { mr: 1 }}>{icon}</SvgIcon>
            {!isMobile && title}
          </Fab>
        </MobileToolTip>
      ) : (
        <FloatingButton />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ elevation: 0 }}
        fullScreen={DialogFullScreen}
        maxWidth={"lg"}
      >
        <IconButton
          sx={{
            position: DialogFullScreen ? "fixed" : "absolute",
            top: DialogFullScreen ? 4 : 1,
            right: DialogFullScreen ? 16 : 1,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              color: (theme) => theme.palette.grey[100],
            },
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {children}
        <AreYouSure open={sure} onAccept={confirmClose} onDecline={closeSure} />
      </Dialog>
    </React.Fragment>
  );
}
