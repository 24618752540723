import {
  alpha,
  Badge,
  Box,
  Button,
  CardActionArea,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  getWeeklyOrderReports,
  weeklyReportEmail,
} from "../../API/Cheques&Pos";
import DateTimePicker from "./DateTimePicker";
import { LoadingButton } from "@mui/lab";
import {
  Cancel,
  CheckCircleOutline,
  Elderly,
  Email,
  ExpandMore,
  Handyman,
  ListAlt,
  Refresh,
  Send,
  Visibility,
  WebStories,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import TagField from "./TagField";
import { toast } from "react-toastify";

export default function OrderReports({
  from = null,
  to = null,
  lowPrivledge = false,
}) {
  const getFirstDayOfWeek = (date) => {
    const firstDay = new Date(date);
    const dayOfWeek = firstDay.getDay();
    const diff = firstDay.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    firstDay.setDate(diff);
    return firstDay;
  };

  const getLastDayOfWeek = (date) => {
    const lastDay = new Date(date);
    const dayOfWeek = lastDay.getDay();
    const diff = lastDay.getDate() + (6 - dayOfWeek);
    lastDay.setDate(diff);
    return lastDay;
  };

  const [date1, setDate1] = React.useState(
    from ? new Date(from) : getFirstDayOfWeek(new Date())
  );
  const [date2, setDate2] = React.useState(
    to ? new Date(to) : getLastDayOfWeek(new Date())
  );

  const [loading, setLoading] = React.useState(true);
  const [fetchDate, setFetchDate] = React.useState(new Date());
  const [dataAge, setDataAge] = React.useState("0 seconds ago");
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (loading) {
      getWeeklyOrderReports({ from: date1, to: date2 })
        .then((res) => {
          setData(res);
          setFetchDate(new Date());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, date1, date2]);

  React.useEffect(() => {
    // each second update the data age
    const interval = setInterval(() => {
      // get the difference in seconds between the fetch date and now
      const diff = Math.floor((new Date() - fetchDate) / 1000);
      if (diff < 60) return setDataAge(`${diff} seconds ago`);
      if (diff < 3600)
        return setDataAge(`${Math.floor(diff / 60)} minutes ago`);
      setDataAge(`${Math.floor(diff / 3600)} hours ago`);
    }, 1000);
    return () => clearInterval(interval);
  }, [fetchDate]);

  const listStyle = {
    maxHeight: "80vh",
    overflowY: "auto",
  };

  const completeRef = React.useRef(null);
  const pendingRef = React.useRef(null);
  const issueRef = React.useRef(null);
  const notApprovedRef = React.useRef(null);
  const b2bRef = React.useRef(null);
  const repairRef = React.useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const totalOrders = React.useMemo(() => {
    return (
      data?.completed?.length +
      data?.pending?.length +
      data?.issue?.length +
      data?.not_approved?.length +
      data?.b2b?.length +
      data?.repair?.length
    );
  }, [data]);

  const OverViewButton = ({
    name,
    scrollRef,
    count,
    allInfo,
    countColour,
    maxAge = 3,
    ignoreAge = false,
    total = 1,
  }) => {
    const oldCount = allInfo?.filter((item) => item.age > maxAge).length;
    const oldPercent = (oldCount / count) * 100;

    return (
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            boxShadow: (theme) => theme.shadows[2],
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <CardActionArea
            onClick={() => scrollToRef(scrollRef)}
            sx={{
              borderRadius: 0,
            }}
          >
            <Paper
              elevation={1}
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                boxShadow: 0,
              }}
            >
              <Typography>{name}</Typography>
              <Stack direction="row" spacing={1}>
                <Chip icon={<WebStories />} color={countColour} label={count} />
              </Stack>
            </Paper>
          </CardActionArea>
          {!ignoreAge ? (
            oldCount > 0 ? (
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  background: `linear-gradient(90deg, #f44336 ${oldPercent.toFixed(
                    0
                  )}%,rgba(121, 37, 31, 0.5) ${oldPercent.toFixed(0)}%)`,
                  px: 1,
                  py: 0.4,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Elderly fontSize="small" />
                  <Typography variant="caption">
                    {oldPercent.toFixed(2)}%
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  [{oldCount}/{count}]
                </Typography>
              </Stack>
            ) : (
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  background: `rgb(102, 187, 106)`,
                  px: 1,
                  py: 0.4,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                }}
              ></Stack>
            )
          ) : (
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
              sx={{
                background: `linear-gradient(90deg,rgb(102, 187, 106) ${(
                  (count / total) *
                  100
                ).toFixed(0)}%,rgba(102, 187, 106, 0.5) ${(
                  (count / total) *
                  100
                ).toFixed(0)}%)`,
                px: 1,
                py: 0.4,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
            >
              <Typography variant="caption">
                {((count / total) * 100).toFixed(0)}%
              </Typography>
              <Typography variant="caption">
                [{count}/{total}]
              </Typography>
            </Stack>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      {!lowPrivledge && (
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems={"center"}
            sx={{ mb: 3 }}
          >
            <DateTimePicker
              label="From"
              value={date1}
              setChange={(date) => setDate1(date)}
            />
            <DateTimePicker
              label="To"
              value={date2}
              setChange={(date) => setDate2(date)}
            />
          </Stack>
          <ReportEmail to={date2} from={date1} />
        </>
      )}
      {lowPrivledge && (
        <Typography variant="overline" sx={{ display: "block" }}>
          {`From: ${date1.toDateString()} To: ${date2.toDateString()}`}
        </Typography>
      )}

      <LoadingButton
        startIcon={<Refresh />}
        onClick={() => setLoading(true)}
        loading={loading}
      >
        Refresh - Last Update {dataAge}
      </LoadingButton>

      <Collapse in={loading}>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
        </Stack>
      </Collapse>
      <Collapse in={!loading}>
        <Stack spacing={2}>
          <Grid container spacing={2} sx={{ pr: 4, pb: 2 }}>
            <OverViewButton
              name="Completed"
              scrollRef={completeRef}
              count={data?.completed?.length}
              allInfo={data?.completed}
              countColour="success"
              ignoreAge
              total={totalOrders}
            />
            <OverViewButton
              name="Pending Delivery"
              scrollRef={pendingRef}
              count={data?.pending?.length}
              allInfo={data?.pending}
              countColour="error"
            />
            <OverViewButton
              name="Issues"
              scrollRef={issueRef}
              count={data?.issue?.length}
              allInfo={data?.issue}
              countColour="error"
            />
            <OverViewButton
              name="Preparing for approval"
              scrollRef={notApprovedRef}
              count={data?.not_approved?.length}
              allInfo={data?.not_approved}
              countColour="warning"
            />
            <OverViewButton
              name="B2B"
              scrollRef={b2bRef}
              count={data?.b2b?.length}
              allInfo={data?.b2b}
              countColour="info"
              maxAge={50}
            />
            <OverViewButton
              name="Repairs"
              scrollRef={repairRef}
              count={data?.repair?.length}
              allInfo={data?.repair}
              countColour="info"
              maxAge={30}
            />
          </Grid>

          <LabelBox
            label="Completed Orders"
            count={data?.completed?.length}
            badgeColor="success"
            refPointer={completeRef}
          >
            <Typography variant="body2" color={"text.secondary"} sx={{ ml: 3 }}>
              Orders delivered fully or marked as completed
            </Typography>
            <List sx={listStyle}>
              {data?.completed?.map((item, index) => (
                <ItemDisplay
                  key={item.orderID}
                  data={item}
                  odd={index % 2 !== 0}
                />
              ))}
            </List>
          </LabelBox>
          <Typography>Pending Orders</Typography>

          <Stack spacing={3} sx={{ mt: 2 }}>
            <LabelBox
              label="Pending Delivery"
              count={data?.pending?.length}
              refPointer={pendingRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have been approved but not delivered
              </Typography>
              <List sx={listStyle}>
                {data?.pending?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="Preparing for approval"
              count={data?.not_approved?.length}
              refPointer={notApprovedRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                That have not been approved by admin yet
              </Typography>
              <List sx={listStyle}>
                {data?.not_approved?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="Issues"
              count={data?.issue?.length}
              refPointer={issueRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have an issue
              </Typography>
              <List sx={listStyle}>
                {data?.issue?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="B2B"
              count={data?.b2b?.length}
              badgeColor="warning"
              refPointer={b2bRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have been marked as B2B
              </Typography>
              <List sx={listStyle}>
                {data?.b2b?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                    showExtra
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="Repairs"
              count={data?.repair?.length}
              badgeColor="info"
              refPointer={repairRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have been marked as repairs
              </Typography>
              <List sx={listStyle}>
                {data?.repair?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                    showExtra
                  />
                ))}
              </List>
            </LabelBox>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
}

function ItemDisplay({ data, odd, showExtra = false }) {
  const [hover, setHover] = React.useState(false);

  const status = data.last_status;
  const repair = data.repair;

  const chipLabel = data.last_status_note
    ? data.last_status_note.length > 20
      ? status + " | " + data.last_status_note.slice(0, 20) + "..."
      : status + " | " + data.last_status_note
    : status;

  let colour = "default";
  switch (status) {
    case "completed":
      colour = "success";
      break;
    case "all items ordered":
      colour = "success";
      break;
    case "issue":
      colour = "error";
      break;
    case "some items ordered":
      colour = "warning";
      break;
    case "partially delivered":
      colour = "warning";
      break;
    case "info":
      colour = "info";
      break;
    default:
      colour = "default";
  }

  const [showItems, setShowItems] = React.useState(false);

  return (
    <ListItem
      sx={{
        ...(odd && { bgcolor: "background.default" }),
        ...(data.age > 2 &&
          status !== "completed" && {
            bgcolor: (theme) =>
              odd
                ? alpha(
                    theme.palette.error.main,
                    theme.palette.action.activatedOpacity
                  )
                : alpha(
                    theme.palette.error.main,
                    theme.palette.action.disabledOpacity
                  ),
          }),
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ListItemText
        disableTypography
        primary={
          <>
            <Box sx={{ display: "block" }}>{data.orderID}</Box>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={"flex-start"}
              spacing={1}
            >
              <Typography>{data.client}</Typography>
              {repair && (
                <Chip icon={<Handyman />} label="Repair" color="info" />
              )}
              <Tooltip
                title={`${data.last_status_note} ~ ${data.last_status_by}`}
              >
                <Chip label={showExtra ? chipLabel : status} color={colour} />
              </Tooltip>
              <InvoiceProgress data={data} />
            </Stack>
          </>
        }
        secondary={
          <>
            <Typography variant="caption" sx={{ display: "block" }}>
              {new Date(data.date).toDateString()} | Days ({data.age})
            </Typography>
            <Collapse in={showItems}>
              <Divider sx={{ my: 1 }} />
              {data.items.map((item, index) => (
                <Typography
                  key={index}
                  variant="caption"
                  sx={{ display: "block" }}
                >
                  {item.partNumber}: {item.description} - QTY:{item.quantity}
                </Typography>
              ))}
            </Collapse>
          </>
        }
      />
      <Fade in={hover}>
        <Stack direction="row" spacing={1}>
          <Button
            startIcon={<ListAlt />}
            color="inherit"
            onClick={() => setShowItems(!showItems)}
          >
            Items
          </Button>
          <Button
            startIcon={<Visibility />}
            color="inherit"
            component={Link}
            to={`/redirect?c=order&i=${data.orderID}`}
            target="_blank"
          >
            View
          </Button>
        </Stack>
      </Fade>
    </ListItem>
  );
}

function LabelBox({
  label,
  count = 0,
  badgeColor = "primary",
  refPointer,
  children,
}) {
  const [open, setOpen] = React.useState(true);
  return (
    <Paper
      variant="outlined"
      ref={refPointer}
      sx={{ scrollMarginTop: "100px" }}
    >
      <CardActionArea
        onClick={() => setOpen(!open)}
        sx={{
          position: "relative",
          borderRadius: 0,
          display: "flex",
          justifyContent: "space-between",
          bgcolor: (theme) => alpha(theme.palette.background.default, 0.5),
          p: 1,
        }}
      >
        <Stack direction="row" spacing={2}>
          <Typography
            sx={{
              position: "relative",
              width: "fit-content",
            }}
          >
            {label}
          </Typography>
          <Badge badgeContent={count} color={badgeColor} />
        </Stack>
        <ExpandMore
          sx={{
            transform: !open ? "rotate(0deg)" : "rotate(180deg)",
          }}
        />
      </CardActionArea>
      <Collapse in={!open} unmountOnExit>
        <Typography sx={{ pl: 1 }} variant="overline">
          Content Hidden
        </Typography>
      </Collapse>
      <Collapse in={open}>{children}</Collapse>
    </Paper>
  );
}

function ReportEmail({ to, from }) {
  const [open, setOpen] = React.useState(false);

  const [emails, setEmails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleSendReportEmail = () => {
    setLoading(true);
    weeklyReportEmail({ to, from, emails })
      .then((res) => {
        toast.success("Email sent successfully");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Stack spacing={2} alignItems="flex-start" sx={{ my: 2 }}>
      <Button
        startIcon={<Email />}
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        {open && "Hide"} Send Email
      </Button>
      <Collapse in={open} unmountOnExit>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Stack spacing={2} alignItems="flex-start">
            {false && (
              <TagField
                label={"Emails"}
                value={emails}
                setValue={setEmails}
                TextFieldProps={{
                  fullWidth: true,
                  helperText: "Please separate each email wihh a comma.",
                }}
                seperator=","
              />
            )}
            <LoadingButton
              startIcon={<Send />}
              loading={loading}
              onClick={handleSendReportEmail}
              variant="outlined"
            >
              Send Email {emails.length < 1 && "to POS and Logisitcs"}
            </LoadingButton>
          </Stack>
        </Paper>
      </Collapse>
    </Stack>
  );
}

function InvoiceProgress({ data }) {
  const totalItems = data.items.length;
  const invoiceCount = data.invoiced;

  const progress = (invoiceCount / totalItems) * 100;

  if (progress === 100) {
    return (
      <Chip
        icon={<CheckCircleOutline />}
        label="Invoiced fully"
        color="success"
      />
    );
  }

  if (progress === 0) {
    return <Chip icon={<Cancel />} label="Not Invoiced" color="error" />;
  }

  return (
    <Chip
      icon={
        <CircularProgress size={20} variant="determinate" value={progress} />
      }
      label={`Invoiced ${invoiceCount}/${totalItems}`}
      color="warning"
    />
  );
}
