import { axiosAuth } from "../../CustomAxios/Protected";
import DownloadFile from "../Components/common/FileDownload";

//Suppliers

export function getSuppliers(data) {
  return axiosAuth
    .get("/pos/purchase/get_suppliers", {
      params: { searchKey: data?.searchKey || "" },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSupplierByID(id) {
  const res = axiosAuth.get("/pos/purchase/get_supplier_id", {
    params: { id: id },
  });
  return res;
}

export function getAllSuppliers(data) {
  return axiosAuth
    .get("/pos/purchase/get_all_suppliers", {
      params: {
        page: data.currentPage,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSupplierPages(data) {
  return axiosAuth.get("/pos/purchase/get_supplier_pages").then((res) => {
    return res.data;
  });
}

export function addSupplier(data) {
  return axiosAuth.post("/pos/purchase/add_supplier", data).then((res) => {
    return res.data;
  });
}

export function editSupplier(data) {
  return axiosAuth.post("/pos/purchase/edit_supplier", data).then((res) => {
    return res.data;
  });
}

export function deleteSupplierCheck(id) {
  return axiosAuth.get("/pos/purchase/delete_supplier_check", {
    params: { id: id },
  });
}

export function deleteSupplier(id) {
  return axiosAuth.get("/pos/purchase/delete_supplier", {
    params: { id: id },
  });
}

export function getClients(data) {
  return axiosAuth
    .get("/pos/purchase/get_clients", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}
export function getCompanies(data) {
  return axiosAuth
    .get("/pos/purchase/get_company", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function getCompaniesInformation(data) {
  return axiosAuth
    .get("/pos/purchase/get_company_information", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

// Orders
export function createOrder(data) {
  let formData = new FormData();

  formData.append("quoteRefrences", JSON.stringify(data.quoteRefrences));
  formData.append("currency", data.currency);
  formData.append("vatExempt", data.vatExempt);
  formData.append("orderDetails", data.orderDetails);
  formData.append("logisticDetails", data.logisticDetails);
  formData.append("clientID", data.clientID);
  formData.append("items", JSON.stringify(data.items));
  formData.append("confirmationType", "");
  formData.append("confirmationDetails", "");
  formData.append("OrderType", data.orderType);
  formData.append("OrderExpected", data.orderExpected);

  if (data?.attachments?.length > 0)
    for (let i = 0; i < data.attachments.length; i++) {
      formData.append("files", data.attachments[i]);
    }
  return axiosAuth
    .post("/pos/orders/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function createOrderAdmin(data) {
  return axiosAuth.post("/pos/orders/create_admin", data).then((res) => {
    return res.data;
  });
}

export function getOrderPages(data) {
  return axiosAuth
    .get("/pos/orders/pages_count", {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: data.status,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getOrders(data, signal) {
  return axiosAuth
    .get(`/pos/orders/get_all`, {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: data.status,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminOrderPages(data) {
  return axiosAuth
    .get("/pos/orders/admin_pages_count", {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: data.status,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminOrderPendingPages(data) {
  return axiosAuth
    .get("/pos/orders/admin_pages_count", {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: false,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminOrders(data, signal) {
  return axiosAuth
    .get(`/pos/orders/get_all_admin`, {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: data.status,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminOrdersPending(data, signal) {
  return axiosAuth
    .get(`/pos/orders/get_all_admin`, {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: false,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getRepairsPages(data) {
  return axiosAuth
    .get("/pos/orders/repairs_pages_count", {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: data.status,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getRepairsPendingPages(data) {
  return axiosAuth
    .get("/pos/orders/repairs_pages_count", {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: false,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getRepairs(data, signal) {
  return axiosAuth
    .get(`/pos/orders/get_all_repairs`, {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: data.status,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getRepairsPending(data, signal) {
  return axiosAuth
    .get(`/pos/orders/get_all_repairs`, {
      params: {
        company: data.companyName,
        currency: data.currency,
        status: false,
        exempt: data.exempt,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        confirmation: data.confirmation,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminOrderByID(id, signal) {
  return axiosAuth
    .get(`/pos/orders/get_order_admin`, {
      params: {
        id,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminOrderOverview(id) {
  return axiosAuth
    .get(`/pos/orders/get_order_overview`, {
      params: {
        id,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminOrderConfirmations(id) {
  return axiosAuth
    .get(`/pos/orders/get_overview_confirmations`, {
      params: {
        id,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function editOverviewConfirmation(data) {
  return axiosAuth
    .post("/pos/orders/edit_overview_confirmation", data)
    .then((res) => {
      return res.data;
    });
}

export function getAllCompanies(data) {
  return axiosAuth
    .get("/pos/orders/get_all_companies", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function getUserCompanies(data) {
  return axiosAuth
    .get("/pos/orders/get_companies", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function queryOrder(data) {
  return axiosAuth.post("/pos/orders/query_orders", data).then((res) => {
    return res.data;
  });
}
export function editOrder(data) {
  return axiosAuth.post("/pos/orders/edit", data).then((res) => {
    return res.data;
  });
}
export function editOrderStatus(data) {
  return axiosAuth.post("/pos/orders/edit_status", data).then((res) => {
    return res.data;
  });
}

export function batchEditOrder(data) {
  return axiosAuth
    .post("/pos/orders/batch_edit_status", { orders: data })
    .then((res) => {
      return res.data;
    });
}

export function approveOrder(id, pending) {
  return axiosAuth
    .get("/pos/orders/approve_order", {
      params: {
        id,
        pending,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function editOrderNote(data) {
  return axiosAuth.post("/pos/orders/edit_note", data).then((res) => {
    return res.data;
  });
}
export function checkConfirmations(data, signal) {
  return axiosAuth
    .post("/pos/orders/check_confirmation", data, signal)
    .then((res) => {
      return res.data;
    });
}

export function getWeeklyOrderReports(data) {
  return axiosAuth
    .get("/pos/orders/order_weekly_report", {
      params: {
        from: data.from,
        to: data.to,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function weeklyReportEmail(data) {
  return axiosAuth
    .post("/pos/orders/order_weekly_report_email", data)
    .then((res) => {
      return res.data;
    });
}

/* -------------------------------------------------------------------------- */
/*                                     POS                                    */
/* -------------------------------------------------------------------------- */
export function createPOS(data) {
  return axiosAuth.post("/pos/purchase/create", data).then((res) => {
    return res.data;
  });
}
export function editPOS(data) {
  return axiosAuth.post("/pos/purchase/edit", data).then((res) => {
    return res.data;
  });
}

export function editPosStatus(data) {
  return axiosAuth.post("/pos/purchase/edit_status", data).then((res) => {
    return res.data;
  });
}

export function editReceived(data) {
  return axiosAuth.post("/pos/purchase/edit_received", data).then((res) => {
    return res.data;
  });
}

export function getEditData(data) {
  return axiosAuth
    .get("/pos/purchase/get_edit_data", {
      params: {
        id: data.id,
        supplier: data.supplier,
        currency: data.currency,
        date: data.date,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getPosByID(id) {
  return axiosAuth
    .get("/pos/purchase/get_pos_id", {
      params: { id: id },
    })
    .then((res) => {
      return res.data;
    });
}

export function getPosPages(data) {
  return axiosAuth
    .get("/pos/purchase/pages_count", {
      params: {
        supplier: data.supplierName,
        currency: data.currency,
        status: data.status,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        client: data.client,
        company: data.company,
        confirmation: data.confirmation,
        orderID: data.orderID,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getPos(data, signal) {
  return axiosAuth
    .get("/pos/purchase/get_all", {
      params: {
        supplier: data.supplierName,
        currency: data.currency,
        status: data.status,
        from: data.from,
        to: data.to,
        page: data.currentPage,
        client: data.client,
        company: data.company,
        confirmation: data.confirmation,
        orderID: data.orderID,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}
export function checkPoNumber(data) {
  return axiosAuth.post("/pos/purchase/check", data).then((res) => {
    return res.data;
  });
}

export function getReference(data) {
  return axiosAuth
    .get("/pos/purchase/references", { params: { order: data.order } })
    .then((res) => {
      return res.data;
    });
}
export function getItemReference(data) {
  return axiosAuth
    .get("/pos/purchase/item_references", { params: { itemID: data.itemID } })
    .then((res) => {
      return res.data;
    });
}

export function deletePo(id) {
  return axiosAuth
    .get("/pos/purchase/delete", {
      params: { poNumber: id },
    })
    .then((res) => {
      return res.data;
    });
}

export function changePOS(po, newPo) {
  return axiosAuth
    .post("/pos/purchase/changePONumber", { po, newPo })
    .then((res) => {
      return res.data;
    });
}

export function getWitholdingReport(data) {
  return axiosAuth
    .get("/pos/purchase/withholding_report", {
      params: {
        ...data,
      },
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `Withholding Report ${new Date()}.xlsx`;
      DownloadFile(response, fileName);

      return {
        status: 200,
        message: "Report generated",
      };
    });
}

//Confirmations errors

export function getConfirmationErrors(data) {
  return axiosAuth
    .get("/pos/purchase/confirmation_errors", {
      params: {
        page: data.currentPage,
      },
    })
    .then((res) => {
      return res.data;
    });
}
export function getConfirmationPages(data) {
  return axiosAuth
    .get("/pos/purchase/confirmation_pages_count", {
      params: {
        page: data.currentPage,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function resolveConfirmationError(data) {
  return axiosAuth
    .post("/pos/purchase/resolve_confirmation_errors", data)
    .then((res) => {
      return res.data;
    });
}

//cheques

export function getChequePages(data) {
  return axiosAuth
    .get("/pos/cheques/pages_count", {
      params: {
        page: data.currentPage,
        supplier: data.supplier,
        from: data.from,
        to: data.to,
      },
    })
    .then((res) => {
      return res.data;
    });
}
export function getCheques(data, signal) {
  return axiosAuth
    .get("/pos/cheques/get_all", {
      params: {
        page: data.currentPage,
        supplier: data.supplier,
        from: data.from,
        to: data.to,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}
export function getCheque(data, signal) {
  return axiosAuth
    .get("/pos/cheques/get_cheque", {
      params: {
        number: data.number,
        bank: data.bank,
        currency: data.currency,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}
export function editCheque(data) {
  return axiosAuth.post("/pos/cheques/edit", data).then((res) => {
    return res.data;
  });
}

export function editChequeDate(data) {
  return axiosAuth.post("/pos/cheques/edit_date", data).then((res) => {
    return res.data;
  });
}

export function updateCheque(data) {
  return axiosAuth.post("/pos/cheques/update", data).then((res) => {
    return res.data;
  });
}

export function checkChequeNumber(data) {
  return axiosAuth.post("/pos/cheques/validate", data).then((res) => {
    return res.data;
  });
}

export function chequeReport(data) {
  return axiosAuth
    .post("/pos/cheques/report", data, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `Cheque Report ${new Date()}.xlsx`;
      DownloadFile(response, fileName);

      return {
        status: 200,
        message: "Cheque Report generated",
      };
    });
}

// item store
export function getStore(data) {
  return axiosAuth
    .get("/pos/purchase/get_some_store", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}
export function createStore(data) {
  return axiosAuth.post("/pos/purchase/create_store", data).then((res) => {
    return res.data;
  });
}

export function editStore(data) {
  return axiosAuth.post("/pos/purchase/edit_store", data).then((res) => {
    return res.data;
  });
}

export function editStoreQuantity(data) {
  return axiosAuth
    .post("/pos/purchase/edit_storeQunatity", data)
    .then((res) => {
      return res.data;
    });
}

export function deleteStore(data) {
  return axiosAuth.post("/pos/purchase/delete_store", data).then((res) => {
    return res.data;
  });
}

export function getStoreItem(data) {
  return axiosAuth
    .get("/pos/purchase/get_store_item", {
      params: { id: data.id },
    })
    .then((res) => {
      return res.data;
    });
}

export function getStoreReport() {
  return axiosAuth
    .get("/pos/purchase/store/get_report", {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `Store Report ${new Date()}.xlsx`;
      DownloadFile(response, fileName);

      return {
        status: 200,
        message: "Store Report generated",
      };
    });
}

// uses lazy loading
export function getStoreItems(data, signal) {
  return axiosAuth
    .get("/pos/purchase/store_items", {
      params: {
        page: data.currentPage,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getStorePages(data) {
  return axiosAuth.get("/pos/purchase/store_pages").then((res) => {
    return res.data;
  });
}
// Store History
// uses lazy loading
export function getStoreItemsHistory(data, signal) {
  return axiosAuth
    .get("/pos/purchase/store_items_history", {
      params: {
        page: data.currentPage,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getStorePagesHistory(data) {
  return axiosAuth.get("/pos/purchase/store_pages_history").then((res) => {
    return res.data;
  });
}

// Invoices

//All
export function checkInvoiceNumber(invoiceID, isCustomer) {
  return axiosAuth
    .get("/pos/invoices/check_invoice_ID", {
      params: {
        invoiceID,
        isCustomer,
      },
    })
    .then((res) => {
      return res.data;
    });
}

//Customer
export function getCustomerInvoicePages({
  currentPage,
  invoice,
  currency,
  client,
  orderID,
  exempt,
  to,
  from,
}) {
  return axiosAuth
    .get("/pos/invoices/customer/pages_count", {
      params: {
        page: currentPage,
        invoice,
        client,
        from,
        to,
        orderID,
        currency,
        exempt,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getCustomerInvoices({
  currentPage,
  invoice,
  currency,
  client,
  orderID,
  exempt,
  to,
  from,
  signal,
}) {
  return axiosAuth
    .get("/pos/invoices/customer/get_all", {
      params: {
        page: currentPage,
        invoice,
        client,
        from,
        to,
        orderID,
        currency,
        exempt,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function get_recent_customer_invoices({ clientID, partialOrderID }) {
  return axiosAuth
    .get("/pos/invoices/customer/get_recent_orders", {
      params: {
        clientID,
        partialOrderID,
      },
    })
    .then((res) => {
      return res.data;
    });
}

//supplier
export function getSupplierInvoicePages({
  currentPage,
  clientID,
  from,
  to,
  posID,
  currency,
  expemt,
}) {
  return axiosAuth
    .get("/pos/invoices/supplier/pages_count", {
      params: {
        page: currentPage,
        client: clientID,
        from: from,
        to: to,
        posID: posID,
        currency: currency,
        expemt: expemt,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSupplierInvoices({
  currentPage,
  clientID,
  from,
  to,
  posID,
  currency,
  expemt,
  signal,
}) {
  return axiosAuth
    .get("/pos/invoices/supplier/get_all", {
      params: {
        page: currentPage,
        client: clientID,
        from: from,
        to: to,
        posID: posID,
        currency: currency,
        expemt: expemt,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function get_recent_supplier_invoices({ supplierID, partialOrderID }) {
  return axiosAuth
    .get("/pos/invoices/supplier/get_recent_pos", {
      params: {
        supplierID,
        partialOrderID,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function addInvoice(data) {
  return axiosAuth.post("/pos/invoices/create", data).then((res) => {
    return res.data;
  });
}

export function getInvoice(id) {
  return axiosAuth
    .get("/pos/invoices/invoice_data", {
      params: {
        id,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function editInvoice(data) {
  return axiosAuth.post("/pos/invoices/edit", data).then((res) => {
    return res.data;
  });
}

export function deleteInvoice(id) {
  return axiosAuth
    .get("/pos/invoices/delete", {
      params: {
        id,
      },
    })
    .then((res) => {
      return res.data;
    });
}
