import {
  Add,
  CheckCircle,
  Close,
  Delete,
  Download,
  Edit,
  Error,
  History,
  Inventory,
  ListAltOutlined,
  Loop,
  MoreVert,
  Opacity,
  Remove,
  Rule,
  ViewAgenda,
  WarningAmberRounded,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
  Typography,
  Link as MLink,
} from "@mui/material";
import React from "react";
import FloatingFormButton from "../../Components/common/FloatingFormButton";
import CreateSelloutForm from "../../TecToday/Components/Toners/Forms/CreateSellout";
import {
  LoadingContext,
  LoadingProvider,
} from "../Components/common/LoadingProvider";
import {
  checkStockError,
  deleteSellout,
  getAllTonerSellout,
  getAllToners,
  getSalesByToner,
  getTonerPages,
  getTonerRatio,
  getTonerSelloutPages,
  getTonerSelloutReport,
  getTonerStock,
  getTonerStockError,
} from "../Components/Toners/API/tonerApi";

import UniversalTable from "../../Components/common/UniversalTable";
import NumberFormat from "../../Components/common/NumberFormater";
import MoreTableButton from "../Components/common/MoreTableButton";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link, Outlet } from "react-router-dom";
import AreYouSure from "../../Components/common/AreYouSure";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import FloatFormGroup from "../../Components/common/FloatFormGroup";
import Reorder from "../Components/Toners/Forms/Reorder";

export default function Toners() {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [reorderOpen, setReorderOpen] = React.useState(false);

  const generateRandomKey = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let key = "";
    for (let i = 0; i < 10; i++) {
      key += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return key;
  };

  const [table1Key, setTable1Key] = React.useState(generateRandomKey());
  const [table2Key, setTable2Key] = React.useState(generateRandomKey());

  const loadNewData = () => {
    setTable1Key(generateRandomKey());
    setTable2Key(generateRandomKey());
    setCreateOpen(false);
  };

  return (
    <>
      <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: 2 }}>
        <Opacity color="primary" />
        <Typography variant="h4" color="primary">
          Toners
        </Typography>
      </Stack>
      <FloatFormGroup>
        {false && (
          <FloatingFormButton
            title="Re order"
            open={reorderOpen}
            setOpen={setReorderOpen}
            icon={<Loop />}
            group
            DialogFullScreen
          >
            <Reorder />
          </FloatingFormButton>
        )}
        <FloatingFormButton
          title="Create Toner Report"
          open={createOpen}
          setOpen={setCreateOpen}
          group
          DialogFullScreen
        >
          <CreateSelloutForm onComplete={loadNewData} />
        </FloatingFormButton>
      </FloatFormGroup>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <LoadingProvider
          key={table1Key}
          getFilterData={() => ({ currentPage: 1 })}
          getData={getAllTonerSellout}
          getPages={getTonerSelloutPages}
        >
          <SelloutTable />
        </LoadingProvider>
      </Paper>
      <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
        <LoadingProvider
          key={table2Key}
          getFilterData={() => ({ currentPage: 1 })}
          getData={getAllToners}
          getPages={getTonerPages}
        >
          <TonersTable />
        </LoadingProvider>
      </Paper>

      <Box sx={{ mt: 2 }}>
        <MLink component={Link} to="/toners/competitive">
          Competitive Pricing
        </MLink>
      </Box>

      <Box sx={{ mt: 2 }}>
        <MLink component={Link} to="/toners/shipping">
          Items shipping details
        </MLink>
      </Box>

      <Outlet context={{ loadNewData, origin: "/toners" }} />
    </>
  );
}

function SelloutTable() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loadFiltered,
    loading,
    data,
  } = React.useContext(LoadingContext);

  const toners = [
    { id: "partnumber", label: "Part Number" },
    { id: "description", label: "Description" },
    { id: "quantity", label: "Quantity" },
    {
      id: "revenue",
      label: "Price",
      component: (value) => NumberFormat(value.revenue.toFixed(2)),
    },
    {
      id: "profit",
      label: "Profit",
      component: (value) => NumberFormat(value.profit.toFixed(2)),
    },
  ];

  const More = ({ value }) => {
    const [open, setOpen] = React.useState(false);

    const DeleteSellout = ({ id, open, setOpen }) => {
      const handleDelete = () => {
        deleteSellout(id).then((res) => {
          if (res.status === "success") {
            toast.success(res.message);
            toast.info("Reload toners to see changes");
            loadFiltered();
          } else {
            toast.error(res);
          }
        });
      };

      return (
        <AreYouSure
          open={open}
          setOpen={setOpen}
          onAccept={handleDelete}
          onDecline={() => setOpen(false)}
          text={`Are you sure you want to delete this sellout? Once deleted it can't be undone`}
          acceptText={"Delete"}
          declineText={"Cancel"}
        />
      );
    };

    return (
      <>
        <MoreTableButton id={value.id}>
          <List dense>
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`view?id=${value.id}`}>
                <ListItemIcon>
                  <ViewAgenda />
                </ListItemIcon>
                <ListItemText primary="More Details" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`edit?id=${value.id}`}>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary="Edit" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => setOpen(true)}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText primary="Delete" />
              </ListItemButton>
            </ListItem>
          </List>
        </MoreTableButton>

        <DeleteSellout id={value.id} open={open} setOpen={setOpen} />
      </>
    );
  };

  const headers = [
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: toners,
      subTitle: "Toners",
      openIcon: <ListAltOutlined />,
    },
    {
      id: "date",
      label: "Date",
      searchable: true,
      component: (value) => new Date(value.date).toLocaleDateString("en-GB"),
    },
    { id: "name", label: "Name", searchable: true },
    {
      id: "transactionType",
      label: "Transaction Type",
      searchable: true,
      component: (value) => (
        <Chip
          label={value.transactionType}
          color={value.transactionType === "debit" ? "error" : "success"}
        />
      ),
    },
    {
      id: "revenue",
      label: "Revenue",
      searchable: true,
      component: (value) => {
        if (value.transactionType === "debit") {
          return `(${NumberFormat(value.revenue.toFixed(2))})`;
        }
        return `${NumberFormat(value.revenue.toFixed(2))}`;
      },
    },
    {
      id: "profit",
      label: "Profit",
      searchable: true,
      component: (value) => <>{NumberFormat(value.profit.toFixed(2))} </>,
    },
    {
      id: "margin",
      label: "Margin",
      component: (value) => `${NumberFormat(value.margin.toFixed(2))}%`,
    },
    {
      id: "stock_error",
      label: "",
      sortParam: "stock_error",
      component: (value) =>
        value.stock_error ? (
          <Tooltip title="Stock Error">
            <Box
              color={(theme) => theme.palette.warning.dark}
              sx={{
                bgcolor: "warning.main",
                px: 0.5,
                py: 0.1,
                width: "fit-content",
                borderRadius: "50%",
              }}
            >
              <WarningAmberRounded color="inherit" />
            </Box>
          </Tooltip>
        ) : null,
      cellProps: { padding: "none", width: "5%" },
    },
    {
      id: "more",
      label: "",
      component: (value) => <More value={value} />,
    },
  ];

  const costs = React.useMemo(
    () =>
      data.reduce((acc, curr) => {
        if (curr.transactionType === "debit") acc += curr.revenue;
        return acc;
      }, 0),
    [data]
  );

  const revenue = React.useMemo(
    () =>
      data.reduce((acc, curr) => {
        if (curr.transactionType === "credit") acc += curr.revenue;
        return acc;
      }, 0),
    [data]
  );

  const profit = React.useMemo(
    () =>
      data.reduce((acc, curr) => {
        if (!curr.name.toLowerCase().includes("faulty"))
          acc = acc + curr.profit;
        return acc;
      }, 0),
    [data]
  );

  const faulty = React.useMemo(
    () =>
      data.reduce((acc, curr) => {
        if (curr.name.toLowerCase().includes("faulty")) acc = acc + curr.profit;
        return acc;
      }, 0),
    [data]
  );

  const minMargin = React.useMemo(() => {
    let min = 0;
    data.forEach((item) => {
      if (item.transactionType === "credit") {
        if (item.margin < min) min = item.margin;
      }
    });
    return min;
  }, [data]);

  const maxMargin = React.useMemo(() => {
    let max = 0;
    data.forEach((item) => {
      if (item.transactionType === "credit") {
        if (item.margin !== 100 && item.margin > max) max = item.margin;
      }
    });
    return max;
  }, [data]);

  const profitPercentage = React.useMemo(() => {
    let count = 0;
    let sum = data.reduce((acc, curr) => {
      if (curr.transactionType === "credit") {
        if (curr.margin === 100) return acc;
        acc += curr.margin;
        count++;
      }
      return acc;
    }, 0);

    return sum / count;
  }, [data]);

  const weightedAverageMarkup = React.useMemo(() => {
    let sum = data.reduce((acc, curr) => {
      if (curr.transactionType === "credit" && curr.margin !== 100)
        acc += curr.revenue * (curr.margin / 100);
      return acc;
    }, 0);

    let revenue = data.reduce((acc, curr) => {
      if (curr.transactionType === "credit") acc += curr.revenue;
      return acc;
    }, 0);

    return (sum / revenue) * 100;
  }, [data]);

  const predictedRevenue = React.useMemo(() => {
    return costs * (weightedAverageMarkup / 100) + costs;
  }, [costs, weightedAverageMarkup]);

  const predictedProfit = React.useMemo(() => {
    return predictedRevenue - costs;
  }, [predictedRevenue, costs]);

  ChartJS.register(ArcElement, ChartTooltip, Legend);

  const [gettingReport, setGettingReport] = React.useState(false);

  const handleGetReport = (expanded) => {
    setGettingReport(true);
    getTonerSelloutReport(expanded)
      .then(() => toast.success("Downloading Report"))
      .catch((err) => toast.error(err))
      .finally(() => setGettingReport(false));
  };

  const [gettingItemReport, setGettingItemReport] = React.useState(false);

  const handleGetItemReport = () => {
    setGettingItemReport(true);
    getSalesByToner()
      .then(() => toast.success("Downloading Report"))
      .catch((err) => toast.error(err))
      .finally(() => setGettingItemReport(false));
  };

  const [downloadArea, setDownloadArea] = React.useState(false);

  const [checking, setChecking] = React.useState(false);

  const handleCheckStockError = () => {
    setChecking(true);
    toast.promise(
      new Promise((resolve, reject) => {
        checkStockError()
          .then((res) => {
            setChecking(false);
            loadFiltered();
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        pending: "Checking all sellouts...",
        success: {
          render: () => {
            return "All sellouts updated";
          },
        },
        error: {
          render: () => {
            return "Something went wrong";
          },
        },
      }
    );
  };

  return (
    <>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" gutterBottom>
            Overall Summary
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              pl: 3,
            }}
          >
            <Typography variant="h4">Profit / Loss</Typography>
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette[revenue - costs > 0 ? "success" : "error"].main,
              }}
              gutterBottom
            >
              {NumberFormat((revenue - costs).toFixed(2))}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                pl: 3,
              }}
            >
              <Typography variant="h5">Revenue</Typography>
              <Typography variant="h6" sx={{ ml: 2 }} gutterBottom>
                {NumberFormat(revenue.toFixed(2))}
              </Typography>

              <Typography variant="h5">Costs</Typography>
              <Stack direction="row" spacing={1}>
                <Typography variant="h6" sx={{ ml: 2 }} gutterBottom>
                  {NumberFormat(costs.toFixed(2))}
                </Typography>
                <Stack direction="column">
                  <Typography variant="overline" color="text.secondary">
                    (Sold {NumberFormat((revenue - profit).toFixed(2))})
                  </Typography>
                </Stack>
              </Stack>

              <Typography variant="h5">Reported Profit</Typography>
              <Stack direction="row" spacing={1}>
                <Typography variant="h6" sx={{ ml: 2 }} gutterBottom>
                  {NumberFormat(profit.toFixed(2))}
                </Typography>
                <Stack direction="column">
                  <Typography variant="overline" color="text.secondary">
                    (FAULTY {NumberFormat(faulty.toFixed(2))})
                  </Typography>
                </Stack>
              </Stack>

              <Typography variant="h5">Markup</Typography>
              <Stack direction="row" spacing={1}>
                <Typography variant="h6" sx={{ ml: 2 }} gutterBottom>
                  {profitPercentage.toFixed(2)}%
                </Typography>
                <Stack direction="column">
                  <Typography variant="overline" color="text.secondary">
                    (MIN. {NumberFormat(minMargin.toFixed(2))})
                  </Typography>
                  <Typography variant="overline" color="text.secondary">
                    (MAX. {NumberFormat(maxMargin.toFixed(2))})
                  </Typography>
                </Stack>
              </Stack>

              <Typography variant="h5">Weighted Average Markup</Typography>
              <Typography variant="h6" sx={{ ml: 2 }} gutterBottom>
                {weightedAverageMarkup.toFixed(2)}%
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ maxHeight: "50dvh" }}>
          <Doughnut
            data={{
              labels: ["Costs", "Revenue", "profit"],
              datasets: [
                {
                  data: [costs, revenue, profit],
                  backgroundColor: ["#f44336", "#2196f3", "#4caf50"],
                },
              ],
            }}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" gutterBottom>
              Forecast
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                pl: 3,
              }}
            >
              <Typography variant="h6">Predicted Revenue</Typography>
              <Typography variant="h7" sx={{ ml: 2 }} gutterBottom>
                {NumberFormat(predictedRevenue.toFixed(2))}
              </Typography>

              <Typography variant="h6">Predicted Profit</Typography>
              <Typography
                variant="h7"
                sx={{
                  ml: 2,
                  color: (theme) =>
                    theme.palette[predictedProfit > 0 ? "success" : "error"]
                      .main,
                }}
                gutterBottom
              >
                {NumberFormat(predictedProfit.toFixed(2))}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                maxHeight: "25dvh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Doughnut
                data={{
                  labels: ["Predicted Revenue", "Predicted Profit"],
                  datasets: [
                    {
                      data: [predictedRevenue, predictedProfit],
                      backgroundColor: ["#2196f3", "#4caf50"],
                    },
                  ],
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Box
          sx={{
            float: "right",
          }}
        >
          <Tooltip title="Check Stock Errors">
            <IconButton onClick={handleCheckStockError} disabled={checking}>
              <Rule />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Reports">
            <IconButton onClick={() => setDownloadArea(!downloadArea)}>
              {downloadArea ? (
                <Close color="info" />
              ) : (
                <Download color="info" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Collapse in={downloadArea}>
          <Stack spacing={2} direction="column">
            <Stack spacing={2} direction="row">
              <LoadingButton
                loading={gettingReport}
                onClick={() => handleGetReport(false)}
                variant="outlined"
                color="info"
                fullWidth
              >
                Download Sellout
              </LoadingButton>

              <LoadingButton
                loading={gettingReport}
                onClick={() => handleGetReport(true)}
                variant="outlined"
                color="info"
                fullWidth
              >
                Download Sellout Expanded
              </LoadingButton>
            </Stack>
            <LoadingButton
              loading={gettingItemReport}
              onClick={handleGetItemReport}
              variant="outlined"
              color="info"
            >
              Download Sellout by Item Excel
            </LoadingButton>
          </Stack>
        </Collapse>
      </Box>

      <Box sx={{ my: 8 }} />

      <UniversalTable
        headers={headers}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        data={data}
        name={"Toner Sellout"}
      />
    </>
  );
}

function TonersTable() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loadFiltered,
    loading,
    data,
  } = React.useContext(LoadingContext);

  const currentStock = React.useMemo(() => {
    return data.reduce((acc, curr) => acc + curr.closing_quantity, 0);
  }, [data]);

  const openStock = React.useMemo(() => {
    return data.reduce((acc, curr) => acc + curr.opening_quantity, 0);
  }, [data]);

  const currentCost = React.useMemo(() => {
    return data.reduce((acc, curr) => acc + curr.total_cost, 0);
  }, [data]);

  const deadStockCost = React.useMemo(() => {
    return data.reduce((acc, curr) => {
      const days = curr.last_sale
        ? Math.round(
            (new Date().getTime() - new Date(curr.last_sale).getTime()) /
              (1000 * 3600 * 24)
          )
        : null;
      if (days > 60) acc += curr.total_cost;
      return acc;
    }, 0);
  }, [data]);

  const deadQty = React.useMemo(() => {
    return data.reduce((acc, curr) => {
      const days = curr.last_sale
        ? Math.round(
            (new Date().getTime() - new Date(curr.last_sale).getTime()) /
              (1000 * 3600 * 24)
          )
        : null;
      if (days > 60) acc += curr.closing_quantity;
      return acc;
    }, 0);
  }, [data]);

  const neverMoved = React.useMemo(() => {
    return data.reduce((acc, curr) => {
      if (curr.last_sale === null) acc += curr.total_cost;
      return acc;
    }, 0);
  }, [data]);

  const neverMovedQty = React.useMemo(() => {
    return data.reduce((acc, curr) => {
      if (curr.last_sale === null) acc += curr.closing_quantity;
      return acc;
    }, 0);
  }, [data]);

  const LastDate = ({ value }) => {
    const days = value.last_sale
      ? Math.round(
          (new Date().getTime() - new Date(value.last_sale).getTime()) /
            (1000 * 3600 * 24)
        )
      : null;

    let color = "success";

    if (days === null)
      return <Chip label="Never Sold" color="info" variant="outlined" />;

    if (days > 30) color = "warning";
    if (days > 60) color = "error";

    if (value.closing_quantity <= 0) color = "default";

    return <Chip label={`${days} days`} color={color} />;
  };

  const maxTurnover = React.useMemo(() => {
    return data.reduce((acc, curr) => {
      if (curr.turnover > acc) acc = curr.turnover;
      return acc;
    }, 0);
  }, [data]);

  const ColourScale = ({ minColour, MaxColour, value, max }) => {
    const c1 = minColour.match(/\w\w/g).map((hex) => parseInt(hex, 16)); //yellow
    const c2 = MaxColour.match(/\w\w/g).map((hex) => parseInt(hex, 16)); //green
    const interpolatedColor = c1.map((c, i) =>
      Math.round(c + ((c2[i] - c) * value) / max)
    );

    const color = `#${interpolatedColor
      .map((c) => c.toString(16).padStart(2, "0"))
      .join("")}`;

    return (
      <Chip
        label={value}
        sx={{
          bgcolor: color,
          width: "100%",
        }}
      />
    );
  };

  const history = [
    {
      id: "type",
      label: "",
      component: (value) => {
        if (value.type) return <Remove color="error" />;
        return <Add color="success" />;
      },
    },
    { id: "sellout", label: "Sellout" },
    { id: "quantity", label: "Quantity" },
    { id: "current_qty", label: "Stock" },
    {
      id: "cost",
      label: "Amount",
      component: (value) => {
        if (value.type) return NumberFormat(value.cost.toFixed(2));
        return `(${NumberFormat(value.cost.toFixed(2))})`;
      },
    },
    { id: "date", label: "Date", date: true },
  ];

  const headers = [
    {
      id: "history",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: history,
      subTitle: "History",
      openIcon: <History />,
    },
    { id: "partnumber", label: "Part Number", searchable: true },
    { id: "description", label: "Description", searchable: true },
    {
      id: "closing_quantity",
      label: "Current Stock",
      component: (value) => (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Chip
            label={`${value.closing_quantity} / ${value.opening_quantity}`}
            color={
              value.closing_quantity <= 0
                ? "error"
                : value.opening_quantity === value.closing_quantity
                ? "warning"
                : "success"
            }
          />
          {value.stock_error && (
            <Tooltip title="Stock Error">
              <Box
                color={(theme) => theme.palette.warning.dark}
                sx={{
                  bgcolor: "warning.main",
                  px: 0.5,
                  py: 0.1,
                  borderRadius: "50%",
                }}
              >
                <WarningAmberRounded color="inherit" />
              </Box>
            </Tooltip>
          )}
        </Stack>
      ),
    },
    {
      id: "cost",
      label: "Cost",
      component: (value) => NumberFormat(value.cost.toFixed(2)),
    },
    {
      id: "ratio",
      label: "Ratio",
      sortParam: "closing_quantity",
      component: (value) => {
        if (value.ratio >= 0) return `${value.ratio}%`;
        return `${(0).toFixed(2)}%`;
      },
    },
    {
      id: "last_sale",
      label: "Last Sale",
      component: (value) => <LastDate value={value} />,
      date: true,
    },
    {
      id: "turnover",
      label: "Monthly",
      component: (value) => (
        <ColourScale
          minColour={"#a87c02"}
          MaxColour={"#0c8c03"}
          value={value.turnover}
          max={maxTurnover}
        />
      ),
    },
    {
      id: "lowStock",
      label: "",
      sortable: false,
      component: (value) =>
        value.lowStock ? (
          <Tooltip title="Low Stock">
            <Badge badgeContent={<Error color="error" {...badgeProps} />}>
              <Inventory color="error" />
            </Badge>
          </Tooltip>
        ) : null,
    },
    {
      id: "more",
      label: "",
      sortable: false,
      component: (value) => (
        <Tooltip title="More Details">
          <IconButton component={Link} to={`viewToner?id=${value.id}`}>
            <MoreVert />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    let final = [];
    data.forEach((item) => {
      final.push({
        ...item,
        ratio: ((item.closing_quantity / currentStock) * 100).toFixed(2),
        lowStock: item.closing_quantity <= item.turnover,
      });
    });
    setFinalData(final);
  }, [data, currentStock]);

  const [gettingStock, setGettingStock] = React.useState(false);

  const handleGetStock = () => {
    setGettingStock(true);
    getTonerStock()
      .then(() => toast.success("Downloading Report"))
      .catch((err) => toast.error(err))
      .finally(() => setGettingStock(false));
  };

  const handleGetRatio = () => {
    setGettingStock(true);
    getTonerRatio()
      .then(() => toast.success("Downloading Report"))
      .catch((err) => toast.error(err))
      .finally(() => setGettingStock(false));
  };

  const handleGetStockErrors = () => {
    setGettingStock(true);
    getTonerStockError()
      .then(() => toast.success("Downloading Report"))
      .catch((err) => toast.error(err))
      .finally(() => setGettingStock(false));
  };

  const HeaderDisplay = ({ title, subtitle, value }) => {
    return (
      <Box>
        <Typography variant="h5">{title}</Typography>
        <Typography
          variant="overline"
          color={(theme) => theme.palette.text.secondary}
        >
          {subtitle}
        </Typography>
        <Typography variant="h6" sx={{ ml: 2 }} gutterBottom>
          {value}
        </Typography>
      </Box>
    );
  };

  const [lowStock, setLowStock] = React.useState(false);

  const handleLowStock = () => {
    setLowStock(!lowStock);
  };

  const badgeProps = {
    sx: {
      fontSize: "1.2rem",
    },
  };

  return (
    <>
      <Box sx={{ float: "right" }}>
        <Collapse in={gettingStock} unmountOnExit>
          <CircularProgress color="info" />
        </Collapse>
        <Collapse in={!gettingStock} unmountOnExit>
          <Tooltip title="Stock Report">
            <IconButton onClick={handleGetStock}>
              <Download color="info" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Ratio Report">
            <Badge
              badgeContent={"%"}
              sx={{ color: "info.main", mr: 0.5 }}
              componentsProps={{ badge: { sx: { mr: 1, mt: 1 } } }}
            >
              <IconButton onClick={handleGetRatio}>
                <Download color="info" />
              </IconButton>
            </Badge>
          </Tooltip>
          <Tooltip title="Stock Errors">
            <Badge
              badgeContent={<Error color="error" {...badgeProps} />}
              sx={{ color: "error.main" }}
              componentsProps={{ badge: { sx: { mr: 1.5, mt: 1 } } }}
            >
              <IconButton onClick={handleGetStockErrors}>
                <Download color="error" />
              </IconButton>
            </Badge>
          </Tooltip>
        </Collapse>
      </Box>

      <Typography variant="h4">Stock Summary</Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 8 }}
        sx={{ p: 2 }}
      >
        <Stack spacing={1} direction="column">
          <HeaderDisplay title="Current Stock" value={currentStock} />
          <HeaderDisplay title="Opening Stock" value={openStock} />
          <HeaderDisplay
            title="Ratio"
            value={((currentStock / openStock) * 100).toFixed(2) + "%"}
          />
        </Stack>
        <Stack spacing={1} direction="column">
          <HeaderDisplay
            title="Current Item Cost"
            value={NumberFormat(currentCost.toFixed(2))}
          />
          <HeaderDisplay
            title="Dead Stock Cost"
            subtitle="(No movement in 60 days)"
            value={`${NumberFormat(deadStockCost.toFixed(2))} (${(
              (deadQty / currentStock) *
              100
            ).toFixed(2)}%)`}
          />
          <HeaderDisplay
            title="Never Sold Cost"
            value={`${NumberFormat(neverMoved.toFixed(2))} (${(
              (neverMovedQty / currentStock) *
              100
            ).toFixed(2)}%)`}
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Tooltip title={lowStock ? "Show all items" : "Show low stock"}>
          <IconButton onClick={handleLowStock}>
            <Badge
              badgeContent={
                lowStock ? (
                  <Error color="error" {...badgeProps} />
                ) : (
                  <CheckCircle color="success" {...badgeProps} />
                )
              }
            >
              <Inventory />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>

      <UniversalTable
        headers={headers}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        data={
          lowStock
            ? finalData.filter((item) => item.closing_quantity <= item.turnover)
            : finalData
        }
        name={"Toners"}
      />
    </>
  );
}
