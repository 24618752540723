import { Close, Delete, Save, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { upload_competitive_price } from "../API/tonerApi";

export default function AddCompetitivePrice() {
  const { origin } = useOutletContext();

  let browseHistory = useNavigate();
  const goBack = React.useCallback(() => {
    browseHistory(origin);
  }, [browseHistory, origin]);

  const [file, setFiles] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFileUpload = () => {
    let formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    upload_competitive_price(formData)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          setFiles(null);
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={true} fullWidth>
      <IconButton
        onClick={goBack}
        sx={{
          position: "absolute",
          top: 6,
          right: 6,
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Upload />
          <Typography variant="h6">Add Competitive Price</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          bgcolor: (theme) => theme.palette.background.default,
        }}
      >
        <UploadFileArea file={file} setFiles={setFiles} />
      </DialogContent>
      <Collapse in={file} unmountOnExit mountOnEnter>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="success"
            startIcon={<Save />}
            loading={loading}
            onClick={handleFileUpload}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
}

function UploadFileArea({ file, setFiles }) {
  const handleChange = (excel) => {
    setFiles(excel);
  };

  console.log(file);

  return (
    <>
      {!file && (
        <Box sx={{ mb: 1 }}>
          <Typography gutterBottom>Please drag drop .XLSX file</Typography>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={["xlsx"]}
            onTypeError={() => toast.error("Only xlsx files are allowed")}
          />
        </Box>
      )}
      {file && (
        <List>
          <ListItem>
            <ListItemText primary={file.name} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  setFiles(null);
                }}
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
      <Alert severity="info">
        The name of the sheet will be used as the date for the competitive
        price. format should be DD_MM_YYYY eg.{" "}
        {new Date().toLocaleDateString("en-GB").replace(/\//g, "_")}
      </Alert>
    </>
  );
}
